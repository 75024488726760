<template>
    <div class="modal-wrapper"
         v-if="user && user.id && !termsIsAccept"
         :class="{hide: !modals.termsAndCondition, isMobile: !isMobile}"
         v-on:mousedown.stop="hideModal">
        <div class="modal" v-on:mousedown.stop>
            <TermsAndCondition/>
            <div class="buttons">
                <div class="button up" @click="setTermState(true)">Accept</div>
                <div class="button down" @click="setTermState(false)">Decline</div>
            </div>
        </div>
    </div>
</template>

<script>
import {MODAL, SET_TERMS_STATE} from "@/store/mutations.type"
import {mapGetters} from "vuex"
import TermsAndCondition from "@/components/TermsAndCondition"
import {isMobile} from "@/common/helpers";

export default {
    name: "TermsAndConditionModal",
    components: {TermsAndCondition},
    methods: {
        isMobile,
        hideModal() {
            this.$store.commit(MODAL, {
                termsAndCondition: false
            })
        },
        setTermState(data) {
            this.$store.commit(SET_TERMS_STATE, data)
            this.hideModal()
        }
    },
    computed: {
        ...mapGetters(['modals', 'user', 'termsIsAccept']),
    }
}
</script>

<style scoped>
.modal-wrapper {
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
    position: relative;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 0;
    color: var(--white);
    font-size: 1.25em;
    font-weight: 800;
}

.buttons .button {
    cursor: pointer;
    padding: 10px;
    border-radius: 2px;
}

.button.up {
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
}

.button.down {
    background-image: linear-gradient(315deg, #eb4511 0%, #b02e0c 74%);
}

.isMobile .modal{
    min-width: 100%;
}

.isMobile .title {
    font-size: 1.5em;
}
</style>
