<template>
    <div class="widget">
        <div class="title">
            <div class="left">
                {{ tokens.widgetOrders.tradingHistory }}
                <div class="sort-box" @click="sortBy('time_close')">
                    <span class="sort">{{ tokens.widgetOrders.sortByTime }}</span>
                    <span class="material-icons"> arrow_drop_{{sortByTime ? 'down' : 'up'}}</span>
                </div>
            </div>
            <div v-if="history.length" class="tab-item download-item" @click="readyToDownload">
                <i class="material-icons pointer">download</i>
                <div v-if='downloadFile' class="download-modal">
                    <div class="download-modal-item">
                        <xlsx-workbook>
                        <xlsx-sheet
                            :collection="sheet.data"
                            v-for="sheet in sheets"
                            :key="sheet.name"
                            :sheet-name="sheet.name"
                        />
                            <xlsx-download :filename="downloadFilename  + '.xlsx'">Excel</xlsx-download>
                        </xlsx-workbook>
                    </div>
                    <div class="download-modal-item">
                        <xlsx-workbook>
                        <xlsx-sheet
                            :collection="sheet.data"
                            v-for="sheet in sheets"
                            :key="sheet.name"
                            :sheet-name="sheet.name"
                        />
                            <xlsx-download :filename="downloadFilename + '.csv'">CSV</xlsx-download>
                        </xlsx-workbook>
                    </div>
                </div>
            </div>
            <i class="material-icons close" v-on:click="hideModal('history')">close</i>
        </div>
        <div class="content scroll" ref="history-scroll">
            <div class="no-orders" :class="{hide: history.length}">
                <i class="material-icons">restore</i>
                <span>{{ tokens.widgetOrders.noHistoryLine1 }}<br/>{{ tokens.widgetOrders.noHistoryLine2 }}</span>
            </div>
            <div class="col">
                <div class="history-item"
                     v-for="order in history"
                     :key="order.id"
                     :class="{
                         active: detailsOrder === order.id,
                     }"
                >
                    <div class="history-head">
                        <div class="row">
                            <div class="col">
                                <div class="time">{{ order.timeStr }}</div>
                                <div class="lesser">{{ tokens.months[order.month] + ' ' + order.day }}</div>
                                <div class="row pointer"
                                     v-on:click="detailsOrder = detailsOrder === order.id ? 0 : order.id">
                                    {{ detailsOrder !== order.id ? tokens.widgetOrders.more : tokens.widgetOrders.less }} <i
                                    class="material-icons">{{ detailsOrder !== order.id ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row symbol-row" @click="changeSymbol(order.symbol)">
                                    <!--                                <img :src="staticBase+symbol.symbol.toUpperCase()+'.png'" /> -->
                                    <div class="symbol"
                                         :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : ''"/>
                                    <span>{{ symbols[order.symbol] ? symbols[order.symbol].alias : order.symbol  }}</span>
                                </div>
                                <div class="lesser">{{ symbols[order.symbol] ? symbols[order.symbol].market.charAt(0).toUpperCase() + symbols[order.symbol].market.slice(1) : ''  }}
                                </div>
                                <div class="lesser" :class="{hide: !order.price_close}">{{symbols[order.symbol] && order.price_open ? order.price_open.toFixed(symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5) : '-' }} → {{ order.price_close.toFixed(symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5)  }}
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="profit" :class="order.profit > 0 ? 'green' : (order.profit < 0 ? 'red' : '')">{{ order.price_close ? ((order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toLocaleString(undefined, {minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision})) : tokens.widgetOrders.cancelled.toUpperCase() }}
                            </div>
                            <div class="lesser">
                                {{ order.volume ? order.volume.toFixed(order.volume > 1000 ? 0 : 2) + ' ' + order.symbol : '-' }}
                            </div>
                        </div>

                    </div>
                    <div class="details" :class="{hide: detailsOrder !== order.id}">
                        <div class="item">
                            <span>{{ tokens.widgetOrders.purchaseTime }}</span>
                            <span>{{ new Date(order.time_open * 1000).toLocaleString() }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.ordersPane.closeTime }}</span>
                            <span>{{ new Date(order.time_close * 1000).toLocaleString() }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceOpen }}</span>
                            <span>{{ order.price_open ? order.price_open?.toFixed(symbols[order.symbol] && symbols[order.symbol]?.precision ? symbols[order.symbol]?.precision : 5) : '-' }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceSl }}</span>
                            <div class="row">
                                <span>{{ order.sl ? order.sl.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-' }}</span>
                            </div>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceTp }}</span>
                            <div class="row">
                                <span>{{ order.tp ? order.tp.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-' }}</span>
                            </div>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.orderType }}</span>
                            <span :class="order.type % 2 ? 'red' : 'green'">
                                    {{ tokens.orderTypes[order.type] }} <i class="material-icons">{{order.type % 2 ? 'arrow_drop_down' : 'arrow_drop_up'}}</i>
                                </span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.volume }}</span>
                            <span>{{ order.volume }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.margin }}</span>
                            <span>{{ order.easy_amount ?  currencySign(activeAccount.currency) + order.easy_amount.toFixed(activeAccount.precision) : '-' }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.pnl }}</span>
                            <span v-if="profitInPrecent">
                                {{ (order.profit ? customToFixed(order.type % 2 ? 100 * ((order.price_open - order.price_close) / order.price_open) : 100 * ((order.price_close - order.price_open) / order.price_open),'withPluseSign') : 0) + '%'}} 
                            </span>
                            <span v-else>{{ (order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toFixed(activeAccount.precision)  }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.commission }}</span>
                            <span>{{ (order.commission > 0 ? '+' : (0 > order.commission ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.commission).toFixed(activeAccount.precision)  }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_MENU, SYMBOL_ACTIVE_UPDATE} from '@/store/mutations.type'
import {STATIC_BASE,PROFIT_IN_PRECENT} from "@/common/config"
import {currencySign, isMobile, customToFixed} from '@/common/helpers'
import XlsxWorkbook from "vue-xlsx/dist/components/XlsxWorkbook";
import XlsxSheet from "vue-xlsx/dist/components/XlsxSheet";
import XlsxDownload from "vue-xlsx/dist/components/XlsxDownload";
import {GET_ORDERS_HISTORY} from "@/store/actions.type";

export default {
    name: 'OrdersHistory',
    components: {XlsxWorkbook, XlsxSheet, XlsxDownload},
    mounted() {
        setInterval(() => {
            if (!this.$refs['history-scroll']) {
                return
            }

            if (this.$refs['history-scroll'].scrollTop + this.$refs['history-scroll'].clientHeight === this.$refs['history-scroll'].scrollHeight && this.modalMenu.history && this.activeAccount.history.length > this.historyPage * 20) {
                this.historyPage++
            }
        }, 1000)
    },
    data() {
        return {
            staticBase: STATIC_BASE,
            historyPage: 1,
            detailsOrder: 0,
            sheets: [{ name: "Orders History", data: [] }],
            downloadFilename: 'OrdersHistory',
            downloadFile: false,
            sortByTime: false,
            profitInPrecent: PROFIT_IN_PRECENT,
        }
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
        sortBy(data) {
            this.sortByTime = !this.sortByTime
            this.$store.dispatch(GET_ORDERS_HISTORY, {
                sortBy: data,
                account: this.activeAccount.id
            })
        },
        changeSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        currencySign,
        customToFixed,
        readyToDownload(){
            this.sheets[0].data = []
            this.downloadFile = !this.downloadFile
            this.sheets[0].data = this.getOrdersForDownload
        }
    },
    computed: {
        ...mapGetters(['modals', 'symbols', 'tokens', 'chartActive', 'activeAccount', 'modalMenu']),
        history() {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

            let history = []

            if (!this.activeAccount.history) {
                return history
            }

            for (let n in this.activeAccount.history.slice(0, this.historyPage * 20)) {
                let order = this.activeAccount.history[n],
                    d = new Date(order.time_close * 1000)

                order.timeStr = ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
                order.dayStr = monthNames[d.getMonth()] + ' ' + ('0' + d.getDate()).slice(-2)
                order.month = monthNames[d.getMonth()]
                order.day = ('0' + d.getDate()).slice(-2)

                order.amount = order.easy_amount || order.margin

                history.push(order)
            }

            return history
        },
        getOrdersForDownload(){
            let orders = []
            this.history.forEach(i => {
                let order = {
                    [this.tokens.ordersPane.symbol]:  i.symbol,
                    [this.tokens.ordersPane.id]:  i.id,
                    [this.tokens.ordersPane.type]:  i.type ? 'Sell' : 'Buy',
                    [this.tokens.ordersPane.volume]:  i.volume,
                    [this.tokens.ordersPane.openPrice]:  i.price_open,
                    [this.tokens.ordersPane.openTime]:  new Date(i.time_open * 1000).toLocaleString(),
                    [this.tokens.ordersPane.closePrice]:  i.price_close,
                    [this.tokens.ordersPane.closeTime]:  new Date(i.time_close * 1000).toLocaleString(),
                    [this.tokens.ordersPane.commission]:  i.commission,
                    [this.tokens.ordersPane.swap]:  i.swap,
                    [this.tokens.ordersPane.pnl]:  i.profit,
                }
                orders.push(order)
            });
            return orders
        }
    }
}
</script>

<style scoped>
.history-item {
    border-top: 1px solid var(--border);
    padding: 15px;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
}

.details .close-position {
    margin-bottom: 10px;
    padding: 4px;
}

.details .item {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    background-color: inherit;
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 8px;
    overflow: initial;
}

.history-item.active {
    border: 1px solid var(--border);
}

.details .item span:last-child {
    color: var(--white-to-black);
    display: flex;
    align-items: center;
}

.details .item span:last-child i {
    font-size: 16px;
}

.history-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.history-item .row {
    align-items: flex-start;
}

.row.pointer {
    align-items: center;
    text-transform: capitalize;
}

.history-item .time {
    color: var(--white-to-black);
    font-size: 1.1em;
    font-weight: 400;
}

.history-item .lesser {
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 5px;
}

.history-item .symbol-row {
    font-size: 1.1em;
    font-weight: 400;
    color: var(--white-to-black)
}

.history-item .symbol-row img {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.history-item .symbol {
    zoom: 0.25;
    margin-right: 20px;
}

@-moz-document url-prefix() {
    .history-item .symbol {
        -moz-transform: scale(0.25);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: -24px;
        top: -24px;
    }

    .history-item .symbol-row {
        position: relative;
        padding-left: 20px;
    }
}

.history-item > .row {
    flex-wrap: nowrap;
}

.history-item .col {
    margin-right: 15px;
}

.history-item > .col:last-child {
    margin-right: 0;
    align-items: flex-end;
}

.history-item .profit {
    font-size: 1.1em;
    font-weight: 600;
    text-align: right;
}

.scroll {
    overflow-x: scroll;
    overflow-y: scroll;
}

.symbol {
    cursor: pointer;
}

.no-orders {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px
}

.no-orders i {
    font-size: 60px;
}

.no-orders span {
    font-size: 1.2em;
    text-align: center;
}

.widget .tab-item.download-item {
    margin-left: auto;
    margin-right: .5em;
    position: relative;
    top: 3px;
 }

.widget .tab-item.download-item > .download-modal {
    border: 1px solid #fff;
    position: absolute;
    top: -10%;
    right: 120%;
    z-index: 888;
    border: 2px solid var(--background-light);
    background-color: var(--background-light);
    border-radius: 2px;
}

.download-modal-item{
    width: 100%;
    padding: 1em;
}

.widget .tab-item.download-item i{
    color: var(--white-to-black);
    font-size: 1.1em;
}

.widget .tab-item.download-item i:active{
    color: var(--brand);
    opacity: .8;
}

.title .left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-right: 0.5em;
    gap: 2em;
}

.sort-box {
    display: flex;
    align-items: center;
}

.sort {
    font-size: .8em;
    color: #ccc;
    cursor: pointer;
}

@media(min-width: 1000px){
    .widget .tab-item.download-item .download-modal-item:hover {
        background-color: var(--brand);
    }
    .widget .tab-item.download-item i:hover{
        color: var(--brand);
        opacity: 1;
    }
}
</style>
