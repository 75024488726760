<template>
    <div class="modal-wrapper" :class="{hide: !modals.closePosition}" v-on:click.stop="hideModal">>
        <div class="modal" v-on:click.stop>
            <div class="title">
                <span>{{tokens.closePositionModal.closeOrder.toUpperCase()}} #{{modalClosePosition.activeOrderId}}</span>
                <i class="material-icons pointer" @click="hideModal">close</i>
            </div>
            <div class="row">
                <div class="col">
                    {{tokens.closePositionModal.areYouSure}}
                    {{activeOrder.type ? tokens.closePositionModal.sell.toUpperCase() : tokens.closePositionModal.buy.toUpperCase()}}
                    {{activeOrder.volume}}
                    {{symbols[activeOrder.symbol] ? symbols[activeOrder.symbol].alias : ''}}?
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span class="button red-button" @click="closePosition(modalClosePosition.activeOrderId, modalClosePosition.index)">
                        <div v-if="closeOrderLoader" class="loader"></div>
                        <span v-else>{{ tokens.closePositionModal.yes }}</span>
                    </span>
                </div>
                <div class="col">
                    <span class="button bg-light-button" @click="hideModal">{{ tokens.closePositionModal.no }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL} from '@/store/mutations.type'
import {CFD_ORDER_CLOSE_REQUEST} from '@/store/actions.type'

export default {
    name: "ClosePositionModal",
    methods: {
        hideModal() {
            if(this.closeOrderLoader) return

            this.$store.commit(MODAL, {
                closePosition: false
            })
        },
        closePosition(id) {
            this.$store.dispatch(CFD_ORDER_CLOSE_REQUEST, {
                id,
                account_id: this.activeAccount.id
            })
        },
    },
    computed: {
        ...mapGetters(['modals', 'activeAccount', 'tokens', 'symbols', 'modalClosePosition','closeOrderLoader']),
        activeOrder() {
            return this.activeAccount.orders[this.modalClosePosition.activeOrderId] || {
                alias: '',
                type: 0,
                volume: 0
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white-to-black);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input {
    width: 100%;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

.red-button {
    color: var(--white);
    background-color: #ac4343;
}

.bg-light-button {
    color: var(--white);
    background-color: var(--background-light);
}

.light-theme .bg-light-button {
    background-color: var(--font);
}

.loader {
    width: 2em;
    height: 2em;
    z-index: 5;
    padding: .4em;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #ffffff;
    --_m:
        conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    to {
        transform: rotate(1turn)
    }
}

@keyframes l2 {
    to {
        transform: rotate(1turn)
    }
}

</style>