<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.account}"
         v-on:click.stop="hideModal">
        <div class="modal" v-on:click.stop>
            <div class="left">
                <div class="block">
                    <div class="name">{{ user.fullname }}</div>
                    <div class="email">{{ user.email }}</div>
                </div>
                <div class="block">
                    <div class="row">
                        <div class="flag" :class="'flag-'+countryToCode(user.country).toLowerCase()"></div>
                        <div class="country">{{ user.country }}</div>
                    </div>
                    <div class="double">
                        <div>
                            <div class="label">{{ tokens.accountModal.dateRegistered }}</div>
                            <div class="val">{{ new Date(user.created * 1000).toISOString().split('T')[0] }}</div>
                        </div>
                        <div>
                            <div class="label">{{ tokens.accountModal.userId }}</div>
                            <div class="val">{{ user.id }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="item" v-on:click="openFullscreen('dashboard')">
                    <i class="material-icons">dashboard</i>
                    <span>{{ tokens.accountModal.dashboard }}</span>
                </div>
                <div class="item" v-on:click="openFullscreen('personal')">
                    <i class="material-icons">account_circle</i>
                    <span>{{ tokens.accountModal.personalData }}</span>
                </div>
                <div class="item" v-on:click="openFullscreen('deposit')">
                    <i class="material-icons">add_circle</i>
                    <span>{{ tokens.accountModal.deposit }}</span>
                </div>
                <div class="item" v-on:click="openFullscreen('withdrawal')">
                    <i class="material-icons">monetization_on</i>
                    <span>{{ tokens.accountModal.withdrawFunds }}</span>
                </div>
                <div class="item" v-on:click="openFullscreen('savings')">
                    <i class="material-icons">savings</i>
                    <span>{{ tokens.accountModal.savings }}</span>
                </div>
                <div class="item" v-on:click="openFullscreen('settings')">
                    <i class="material-icons">settings</i>
                    <span>{{ tokens.accountModal.settings }}</span>
                </div>
                <div class="border"></div>
                <div class="item" v-on:click="logout">
                    <i class="material-icons">exit_to_app</i>
                    <span>{{ tokens.accountModal.logout }}</span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {MODAL, MODAL_FULLSCREEN} from "@/store/mutations.type";
import {countryToCode} from "@/common/helpers";
import {STATIC_BASE} from "@/common/config"
import {LOGOUT} from "@/store/actions.type";

export default {
    name: "AccountModal",
    data() {
        return {
            staticBase: STATIC_BASE
        }
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                account: false
            })
        },
        openFullscreen(page) {
            this.$store.commit(MODAL_FULLSCREEN, {
                page
            })
            this.$store.commit(MODAL, {
                account: false,
                fullscreen: true
            })
        },
        logout() {
            this.$store.dispatch(LOGOUT)
        },
        countryToCode
    },
    computed: {
        ...mapGetters(['modals', 'user', 'tokens'])
    }
}
</script>

<style scoped>
    .modal-wrapper {
        background: transparent;
    }

    .modal {
        position: fixed;
        z-index: 2;
        right: 0;
        top: 81px;
        width: 500px;
        display: flex;
        align-items: stretch;
    }

    .left {
        flex: 0 0 300px;
        padding: 15px 25px;
        background: var(--background-dark-mid);
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .right {
        flex: 1 1 auto;
        padding: 15px;
        display: flex;
        flex-direction: column;
    }

    .left .block {
        flex: 1 1 50%;
        padding: 15px 0;
        flex-direction: column;
        align-items: stretch;
    }

    .left .block:first-child {
        border-bottom: 1px solid var(--border);
    }

    .name {
        font-size: 20px;
        color: var(--white);
        font-weight: 400;
        margin-bottom: 15px;
    }

    .email {
        margin-bottom: 30px;
    }

    .country, .email {
        font-size: 1.1em;
        font-weight: 400;
    }

    .flag {
        margin-right: 10px;
    }

    .double {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .double > div {
        display: flex;
        flex-direction: column;
        flex: 1 1 50%
    }

    .double > div:first-child {
        margin-right: 20px;
    }

    .double .label {
        font-size: 0.9em;
        margin-bottom: 5px;
        font-weight: 400;
    }

    .double .val {
        color: var(--white);
        font-weight: 400;
        font-size: 1.1em;
    }

    .right .item {
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color ease-in-out 0.3s;
        font-size: 1.1em;
    }

    .right .item i {
        font-size: 1.5em;
        margin-right: 10px;
        transition: color ease-in-out 0.3s;
    }

    .right .item span {
        color: var(--white);
        font-weight: 400;
    }

    .border {
        padding-top: 5px;
        border-bottom: 1px solid var(--border);
        margin-bottom: 5px;
    }

    .right .item:hover {
        background-color: var(--background-dark-mid);
    }

    .right .item:hover i {
        color: var(--white)
    }

    .mobile .modal {
        top: 36px
    }

    @media screen and (orientation: portrait) {
        .mobile .modal {
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 66px);
        }
    }
</style>