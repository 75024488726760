<template>
    <div class="modal-wrapper" :class="{hide: !modals.forgotPassword, newDesign: newDesign}">
        <div class="modal">
            <div class="title">
                <span>{{ tokens.forgotPasswordModal.forgotPassword.toUpperCase() }}</span>
                <i class="material-icons close pointer" v-on:click="hideModal">close</i>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-row">
                        <label>{{ tokens.forgotPasswordModal.submitEmail }}</label>
                        <input class="form-control" v-model="email" :placeholder="tokens.loginModal.enterEmail"/>
                        <div class="button green-button" @click="restorePassword">
                            {{ tokens.forgotPasswordModal.submit.toUpperCase() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {NEW_DESIGN} from "@/common/config";

import {mapGetters} from "vuex"
import {MODAL} from "@/store/mutations.type"
import {PASSWORD_RESTORE} from "@/store/actions.type"

export default {
    name: "ForgotPasswordModal",
    data() {
        return {
            email: '',
            newDesign: NEW_DESIGN,
        }
    },
    methods: {
        hideModal() {
            this.email = ''
            this.$store.commit(MODAL, {
                forgotPassword: false
            })
        },
        restorePassword() {
            this.$store.dispatch(PASSWORD_RESTORE, {
                email: this.email.trim()
            })
            this.$store.commit(MODAL, {
                forgotPassword: false
            })
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals'])
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.newDesign .modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: linear-gradient(149.09deg, rgba(155, 162, 187, 0.8) 2.06%, rgba(57, 57, 57, 0.8) 77.03%);
    border: 1px solid #CECECE;
    border-radius: 10px;
    padding: 30px 40px;
    z-index: 1100;
}

.newDesign .title {
    color: var(--white);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newDesign .col {
    margin-right: 0 !important;
}

.newDesign .title i {
    font-size: 19px;
}

.newDesign .col {
    flex: 1 1 50%;
    justify-content: flex-start;
}


.newDesign label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.newDesign input {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.newDesign .form-row:last-child {
    margin-bottom: 0;
}

.newDesign .row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.newDesign ::placeholder {
    color: transparent;
}

.newDesign .button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    cursor: pointer;
    background: #247243;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}

/*Forgot SharkSoft*/

.modal {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white-to-black);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input {
    width: 100%;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}
</style>
