<template>
    <div class="inner">
        <div class="specification">
            <div class="list">
                <div class="left">
                    <div class="specification-title">{{ tokens.assetInfoModal.contractSpecification }}</div>
                    <ul>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.symbol }}</div>
                            <div class="value">{{ symbols[symbolActive].symbol }}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.name }}</div>
                            <div class="value">{{ symbols[symbolActive].alias }}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.market }}</div>
                            <div class="value">{{ symbols[symbolActive].group.charAt(0).toUpperCase() + symbols[symbolActive].group.slice(1) }}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.baseCurrency }}</div>
                            <div class="value">{{ symbols[symbolActive].base_currency }}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.digits }}</div>
                            <div class="value">{{ symbols[symbolActive].precision }}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.lotSize }}</div>
                            <div class="value">{{ symbols[symbolActive].lot.toLocaleString(undefined)}}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.lotStep }}</div>
                            <div class="value">{{ symbols[symbolActive].lot_step }}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.minVolume }}</div>
                            <div class="value">{{ symbols[symbolActive].volume_min.toLocaleString(undefined) }}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.maxVolume }}</div>
                            <div class="value">{{ symbols[symbolActive].volume_max.toLocaleString(undefined) }}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.leverage }}</div>
                            <div class="value">{{ symbols[symbolActive].leverage_default ? '1:'+symbols[symbolActive].leverage_default : 'account'}}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.commission }}</div>
                            <div class="value">{{ symbols[symbolActive].commission.toFixed(1) + '%'}}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.swapLong }}</div>
                            <div class="value">{{ symbols[symbolActive].swap_long.toFixed(1) + '%'}}</div>
                        </li>
                        <li>
                            <div class="title">{{ tokens.assetInfoModal.swapShort }}</div>
                            <div class="value">{{ symbols[symbolActive].swap_short.toFixed(1) + '%'}}</div>
                        </li>
                    </ul>
                </div>
                <div class="right">
                    <div class="specification-title">{{ tokens.assetInfoModal.schedule }}</div>
                    <div class="trading">
                        <div class="header">
                            <div>{{ tokens.assetInfoModal.weekday }}</div>
                            <div>{{ tokens.assetInfoModal.tradingTime }}</div>
                        </div>
                        <div v-for="(session, n) in tradingSessions" :key="n" class="day">
                            <div>{{ dayOfWeek[n] }}</div>
                            <div>{{ session }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "TradingConditions",
    props: ['symbolActive', 'chart'],
    computed: {
        ...mapGetters(['symbols', 'modalAssetsInfo', 'sessions', 'tokens']),
        tradingSessions() {
            const glueSession = session => {
                let r = []

                for (let i = 0; i < 7; i++) {
                    r = r.concat(session[i])
                }

                return r
            }

            const unglueSession = session => {
                let r = []

                for (let i = 0; i < 7; i++) {
                    r.push(session.slice(i * 24, (i + 1) * 24))
                }

                return r
            };

            let timezone = Math.floor(new Date().getTimezoneOffset() / 60),
                session = this.sessions[this.modalAssetsInfo.symbol]

            session = glueSession(session)

            while (timezone !== 0) {
                if (timezone > 0) {
                    session.push(session.shift())
                    timezone--
                } else {
                    session.unshift(session.pop())
                    timezone++
                }
            }

            session = unglueSession(session)

            const makeSession = (start, stop) => {
                const formatTime = (time) => {
                    let hours = Math.floor(time)
                    let minutes = Math.round((time - hours) * 60)
                    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2)
                }

                return formatTime(start) + ' - ' + formatTime(stop)
            }

            let sessions = []
            for (let d = 0; d < 7; d++) {
                let daySessions = []
                let s = session[d],
                    start = null,
                    stop = null,
                    started = false

                for (let i = 0; i < 24; i++) {
                    let cur = s[i]
                    let next = s[i + 1] !== undefined ? s[i + 1] : 0

                    if (cur > 0 && !started) {
                        start = i + (cur < 1 ? cur : 0);
                        started = true;
                    }

                    if (started) {
                        stop = i + (cur < 1 ? cur : 1);
                    }

                    if ((cur > 0 && next === 0) || (i === 23 && started)) {
                        daySessions.push(makeSession(start, stop));
                        started = false;
                    }
                }

                sessions.push(daySessions.length ? daySessions.join(', ') : this.tokens.assetInfoModal.closed)
            }

            return sessions
        },
        dayOfWeek() {
            return [
                this.tokens.assetInfoModal.sunday,
                this.tokens.assetInfoModal.monday,
                this.tokens.assetInfoModal.tuesday,
                this.tokens.assetInfoModal.wednesday,
                this.tokens.assetInfoModal.thursday,
                this.tokens.assetInfoModal.friday,
                this.tokens.assetInfoModal.saturday,
            ]
        }
    },
}
</script>

<style scoped>

.inner {
    display: grid;
    grid-template-rows: 1fr;
    padding: 0px 10px;
    gap: 50px;
}

.specification-title {
    font-size: 1.5em;
    color: var(--white-to-black);
    font-weight: 400;
}

.right .specification-title {
    padding-left: 5px;
}

.specification-title,
.trading-title {
    margin-bottom: 20px;
    text-align: left;
}

.list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}


.left li,
.right li {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    margin-bottom: 10px;
}

.left li:last-child,
.right li:last-child {
    margin-bottom: 0px;
}

.left .tooltip {
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-items: center center;
    gap: 5px;
}

.left .tooltip i {
    font-size: .6em;
}


.left .value,
.right .value {
    color: var(--white-to-black);
}

ul {
    list-style: none;
    margin-top: 10px;
}

ul:first-child {
    margin-top: 0px;
}

.trading-title span {
    color: var(--white-to-black);
}

.description p {
    margin-top: 10px;
}

.header {
    padding-bottom: 10px;
    border-bottom: 1px solid #adadad;
    margin-bottom: 10px;
    padding-left: 5px;
}

.header,
.day {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.day {
    padding: 8px 0 8px 5px;
    color: var(--white-to-black);
    transition: background-color .3s ease-in-out;
}

.day:hover {
    background-color: var(--border);
    border-radius: 3px;
}

@media (max-width: 500px) {
    .list {
        grid-template-columns: none;
        grid-template-rows: repeat(2, auto);
    }

    .day {
        font-size: 11px;
    }
}

</style>
