<template>
    <div class="widget">
        <div class="title">{{ tokens.widgetMarketNews.marketNews }} <i class="material-icons close" @click="hideModal('news')">close</i> </div>
        <div class="content scroll">
            <div v-for="news in newsFeed"
                :key="news.id"
                 class="news"
            >
                <div class="time">{{ timeToString(news.time * 1000) }}</div>
                <div class="row">
                    <div class="col">
                        <a :href="news.url" target="_blank">
                            <img :src="news.img" />
                        </a>
                        <a :href="news.url" target="_blank" class="news-title">{{ news.title }}</a>
                        <div class="summary" v-html="news.summary"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {MODAL, MODAL_MENU} from '@/store/mutations.type'
import {isMobile, timeToString} from '@/common/helpers'
import {mapGetters} from 'vuex'

export default {
    name: 'MarketNews',
    mounted() {
        setInterval(() => {
            if (!this.$refs['news-scroll']) {
                return
            }

            if (this.$refs['news-scroll'].scrollTop + this.$refs['news-scroll'].clientHeight === this.$refs['news-scroll'].scrollHeight && this.modalMenu.news && this.activeAccount.news.length > this.newsPage * 20) {
                this.newsPage++
            }
        }, 1000)
    },
    data() {
        return {
            newsPage: 1
        }
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for(let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
        timeToString
    },
    computed: {
        ...mapGetters(['modalMenu', 'tokens', 'feed']),
        newsFeed() {
            let feed = []

            for(let n in this.feed.slice(0, this.newsPage * 20)) {
                feed.push(this.feed[n])
            }

            feed =  feed.map((i)=>{
                if(i.summary.includes('img')){
                    i.summary = i.summary.replace("img", 'img style="max-width: 340px; max-height: 230px"')
                }
                return i
            })

            return feed
        }
    }
}
</script>

<style scoped>
    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .news {
        padding: 15px 0;
        margin: 0 10px;
        border-bottom: 1px solid var(--border);
    }

    .news .row, .news .col {
        display: block;
    }

    .news > a {
        display: inline;
    }

    .news img {
        width: 80px;
        height: 53px;
        object-fit: cover;
        float: left;
        margin: 0 8px 8px 0;
    }

    .news-title, .news-title:hover {
        color: var(--white-to-black);
        font-weight: 400;
        font-size: 1em;
        text-decoration: none;
    }

    .time {
        margin-bottom: 5px;
        font-size: 0.8em;
        color: var(--font);
        font-weight: 400;
    }

    .summary {
        font-weight: 400;
        margin-top: 5px;
    }
</style>