<template>
    <div class="orders-pane" :class="{hide: !ordersPane}">
        <div class="tabs">
            <div class="tab-item" :class="{active: tabActive === 'active'}" @click="activateTab('active')">
                <span>{{ tokens.ordersPane.ordersActive.toUpperCase() }}</span>
            </div>
            <div class="tab-item" :class="{active: tabActive === 'history'}" @click="activateTab('history')">
                <span>{{ tokens.ordersPane.ordersHistory.toUpperCase() }}</span>
            </div>
            <div v-if="tabActive === 'history' && sheets[0].data.length" class="tab-item download-item" @click="downloadFile = !downloadFile">
                <i class="material-icons pointer">download</i>
                <div v-if='downloadFile' class="download-modal">
                    <div class="download-modal-item">
                        <xlsx-workbook>
                        <xlsx-sheet
                            :collection="sheet.data"
                            v-for="sheet in sheets"
                            :key="sheet.name"
                            :sheet-name="sheet.name"
                        />
                            <xlsx-download :filename="downloadFilename  + '.xlsx'">Excel</xlsx-download>
                        </xlsx-workbook>
                    </div>
                    <div class="download-modal-item">
                        <xlsx-workbook>
                        <xlsx-sheet
                            :collection="sheet.data"
                            v-for="sheet in sheets"
                            :key="sheet.name"
                            :sheet-name="sheet.name"
                        />
                            <xlsx-download :filename="downloadFilename + '.csv'">CSV</xlsx-download>
                        </xlsx-workbook>
                    </div>
                </div>
            </div>
        </div>

        <div class="tables-container" id="tables-container">
            <OrdersTable  :class="{hide: tabActive !== 'active'}" />
            <HistoryTable @send-ordersHistory="getOrdersHistoryFromChild" :class="{hide: tabActive !== 'history'}" :active="tabActive === 'history'" />
        </div>

        <i class="material-icons expander pointer" @click="closeOrdersPane()">expand_more</i>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ORDERS_PANE, HISTORY_TABLE_IS_ACTIVE} from '@/store/mutations.type'
import OrdersTable from '@/components/OrdersTable'
import HistoryTable from '@/components/HistoryTable'
import XlsxWorkbook from "../../node_modules/vue-xlsx/dist/components/XlsxWorkbook";
import XlsxSheet from "../../node_modules/vue-xlsx/dist/components/XlsxSheet";
import XlsxDownload from "../../node_modules/vue-xlsx/dist/components/XlsxDownload";

export default {
    name: 'OrdersPane',
    data() {
        return {
            tabActive: 'active',
            sheets: [{ name: "Orders History", data: [] }],
            downloadFilename: 'OrdersHistory',
            downloadFile: false
        }
    },
    components: {HistoryTable, OrdersTable, XlsxWorkbook, XlsxSheet, XlsxDownload},
    methods: {
        closeOrdersPane() {
            this.$store.commit(ORDERS_PANE, false)
        },
        activateTab(name) {
            this.tabActive = name
            if(name === 'history') {
                this.$store.commit(HISTORY_TABLE_IS_ACTIVE, true)
            } else {
                this.$store.commit(HISTORY_TABLE_IS_ACTIVE, false)
            }
        },
        getOrdersHistoryFromChild(orders){
            this.sheets[0].data = []
            orders.history.forEach(i => {
                let order = {
                    [this.tokens.ordersPane.symbol]:  i.symbol,
                    [this.tokens.ordersPane.id]:  i.id,
                    [this.tokens.ordersPane.type]:  i.type ? 'Sell' : 'Buy',
                    [this.tokens.ordersPane.volume]:  i.volume,
                    [this.tokens.ordersPane.openPrice]:  i.price_open,
                    [this.tokens.ordersPane.openTime]:  new Date(i.time_open * 1000).toLocaleString(),
                    [this.tokens.ordersPane.closePrice]:  i.price_close,
                    [this.tokens.ordersPane.closeTime]:  new Date(i.time_close * 1000).toLocaleString(),
                    [this.tokens.ordersPane.commission]:  i.commission,
                    [this.tokens.ordersPane.swap]:  i.swap,
                    [this.tokens.ordersPane.pnl]:  i.profit,
                }
                this.sheets[0].data.push(order)
            });
        },
    },
    computed: {
        ...mapGetters(['ordersPane','tokens'])
    }
}
</script>

<style scoped>
    .orders-pane {
        flex: 1 1 110px;
        min-height: 110px;
        width: 100%;
        padding: 5px;
        border-left: 1px solid var(--background-light);
        position: relative;
        display: grid;
        grid-template-rows: auto 1fr;
    }

    .tabs {
        display: flex;
        align-items: center;
        margin: 0;
        padding-bottom: 3px;
    }

    .tabs .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 1.1em;
        color: var(--white-to-black);
        border-bottom: 1px solid transparent;
        padding: 5px 0;
        cursor: pointer;
    }

    .tabs .tab-item.active {
        color: var(--brand);
        border-bottom-color: var(--brand);
    }

    .tabs .tab-item.download-item {
        padding: 0;
        margin-left: auto;
        margin-right: 2em;
        position: relative;
    }

    .tabs .tab-item.download-item > .download-modal {
       border: 1px solid #fff;
       position: absolute;
       right: 120%;
       z-index: 888;
       border: 2px solid var(--background-light);
       background-color: var(--background-light);
       border-radius: 2px;
    }

    .download-modal-item{
        width: 100%;
        padding: 1em;
    }

    .tabs .tab-item.download-item i{
        color: var(--white-to-black);
        opacity: .6;
        font-size: 1.6em;
    }

    .tabs .tab-item.download-item i:active{
        color: var(--brand);
        opacity: .8;
    }

    @media(min-width: 1000px){
        .tabs .tab-item.download-item .download-modal-item:hover {
            background-color: var(--brand);
        }
        .tabs .tab-item.download-item i:hover{
            color: var(--brand);
            opacity: 1;
        }
    }
    
    .tabs span {
        width: 100%;
        text-align: center;
        padding: 0 20px;
    }

    .tabs .tab-item:first-child span {
        border-right: 1px solid var(--border);
    }

    .tables-container {
        overflow: auto;
    }

    .expander {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 30px;
    }
</style>