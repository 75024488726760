<template>
    <div class="menu-modal" :class="{hide: !modals.menu || !modalMenuSplit}">
        <MarketWatch class="item" :class="{hide: !modalMenu.market}" />
        <OpenOrders class="item" :class="{hide: !modalMenu.orders}" />
        <OrdersHistory class="item" :class="{hide: !modalMenu.history}" />
        <EconomicCalendar class="item" :class="{hide: !modalMenu.calendar}" />
        <MarketNews class="item" :class="{hide: !modalMenu.news}" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
    MODAL, MODAL_MENU
} from '@/store/mutations.type'
import {isMobile} from '@/common/helpers'
import OpenOrders from '@/components/Widgets/OpenOrders'
import OrdersHistory from '@/components/Widgets/OrdersHistory'
import MarketWatch from '@/components/Widgets/MarketWatch'
import EconomicCalendar from '@/components/Widgets/EconomicCalendar'
import MarketNews from '@/components/Widgets/MarketNews'

export default {
    name: "MenuModal",
    components: {MarketNews, EconomicCalendar, MarketWatch, OrdersHistory, OpenOrders},
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                menu: false,
                sideMenu: !isMobile()
            })
        }
    },
    computed: {
        ...mapGetters(['modals', 'modalMenu']),
        modalMenuSplit() {
            let i = 0
            for(let n in this.modalMenu) {
                if (this.modalMenu[n]) {
                    i++
                }
            }

            return i
        }
    },
    watch: {
        modals: {
            deep: true,
            handler(val) {
                if (!val.menu) {
                    this.$store.commit(MODAL_MENU, {
                        orders: false,
                        history: false,
                        market: false,
                        calendar: false,
                        news: false
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
    .menu-modal {
        flex: 0 0 340px;
        display: flex;
        flex-direction: column;
        border-left: 1px solid var(--border);
        margin-bottom: -6px;
        padding-right: 10px;
    }

    @media screen and (orientation: portrait) {
        .mobile .menu-modal {
            flex: 0 0 100%
        }
    }
</style>