<template>
    <div class="modal-otp" :class="{hide: !modals.otp}">
        <div class="modal">
            <div class="title">
                <span>{{ tokens.loginModal.twoFactorAuth.toUpperCase() }}</span>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-row">
                        <label>{{tokens.fullScreenSettings.twoFactorCode}}</label>
                        <input class="form-control" v-model="otp" @keyup.enter="login"/>
                        <div class="button green-button" @click="login">
                            {{ tokens.forgotPasswordModal.submit.toUpperCase() }}
                        </div>
                        <div class="form-login">
                            <p>{{tokens.loginModal.goTo}}<span @click="modals.otp = false">{{tokens.loginModal.login}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {LOGIN} from "@/store/actions.type"

export default {
    name: "TwoFactorAuthenticationModal",
    props: ['email', 'password'],
    data() {
        return {
            otp: '',
        }
    },
    methods: {
        login() {
            this.$store.dispatch(LOGIN, {
                email: this.email.trim(),
                password: this.password.trim(),
                otp: this.otp
            })
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals'])
    }
}
</script>

<style scoped>
.modal-otp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: linear-gradient(149.09deg, rgba(155, 162, 187, 0.8) 2.06%, rgba(57, 57, 57, 0.8) 77.03%);
    border: 1px solid #CECECE;
    border-radius: 10px;
    padding: 30px 40px;
    z-index: 1100;
    position: absolute;
}

.title {
    color: var(--white);
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title i {
    font-size: 19px;
}

.col {
    margin-right: 0;
}

label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.row:last-child{
    margin: 0;
}

.form-login{
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
}

.form-login p {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.form-login p span {
    text-decoration: underline;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--white);
    margin-left: 5px;
    cursor: pointer;
}

input {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.form-row:last-child {
    margin-bottom: 0;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    cursor: pointer;
    background: #247243;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}
</style>
