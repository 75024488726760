<template>
    <div class="widget">
        <div class="title">{{ tokens.widgetMarketWatch.marketWatch }} <i class="material-icons close"
                                                                         @click="hideModal('market')">close</i></div>
        <div class="content scroll">
            <input type="text" value="" class="form-control search" name="my-search-market-watch" ref="search" autocomplete="off"
                   v-model="search" :placeholder="tokens.widgetMarketWatch.search"/>
            <i class="material-icons search-ico">search</i>
            <div class="col">
                <div v-for="(cat, i) in marketCats"
                     :key="i"
                >
                    <div class="cat-title" @click="collapsed[i] = search === '' ? !collapsed[i] : collapsed[i]"
                         :class="{hide: !cat.cnt}">
                        <div class="row">
                            <i class="material-icons">{{ cat.ico }}</i>
                            <span>{{ cat.name }}</span>
                        </div>
                        <div class="row-2">
                            <div class="cnt">{{ cat.cnt }}</div>
                            <i class="material-icons">{{
                                    collapsed[i] && search === '' ? 'expand_more' : 'expand_less'
                                }}</i>
                        </div>
                    </div>
                    <template
                        v-if="!((marketFilter && symbols[symbol.symbol].market !== marketFilter) || (search === '' && collapsed[i]))">
                        <div class="market-item"
                             @click="changeSymbol(symbol.symbol)"
                             v-for="symbol in cat.symbols"
                             :key="symbol.symbol"
                             :class="{
                         hide: (marketFilter && symbols[symbol.symbol].market !== marketFilter) || (search === '' && collapsed[i]),
                         active: charts[chartActive] && charts[chartActive].symbol === symbol.symbol
                     }"
                        >
                            <div class="row">
                                <div class="col">
                                    <div class="name">
                                        <div class="row" :class="sessionsState[symbol.symbol] ? '' : 'close' ">
                                            <!--                                        <img :src="staticBase+symbol.symbol.toUpperCase()+'.png'" />-->
                                            <div class="symbol"
                                                 :class="'symbol-'+symbol.symbol.toUpperCase().replace('.','-')"/>
                                            <span>{{
                                                    symbols[symbol.symbol] ? symbols[symbol.symbol].alias : symbol.symbol
                                                }}</span>
                                        </div>
                                        <div class="row">
                                            <div class="price"
                                                 :class="sessionsState[symbol.symbol] ? (symbol.quote ? symbol.quote.dirBid : '') : 'close'">
                                                {{
                                                    symbol.quote && symbol.quote.bid ? symbol.quote.bid.toFixed(symbol.precision) : '-'
                                                }}
                                            </div>
                                            <div class="smaller">
                                                <div class="pct"
                                                     :class="{
                                                    red: symbol.quote && symbol.quote.changePct < 0,
                                                    green: symbol.quote && 0 < symbol.quote.changePct,
                                                    hide: !sessionsState[symbol.symbol]
                                                 }">
                                                    {{
                                                        (symbol.quote && symbol.quote.changePct > 0 ? '+' : '') + (symbol.quote && symbol.quote.changePct ? symbol.quote.changePct : 0).toFixed(2) + '%'
                                                    }}
                                                </div>
                                                <div :class="{hide: sessionsState[symbol.symbol]}" class="pct close">
                                                    Closed
                                                </div>
                                            </div>
                                            <i class="material-icons add" @click.stop="addChart(symbol.symbol)">add</i>
                                            <i class="material-icons info"
                                               @click.stop="showAssetsInfoModal(symbol.symbol)">info_outlined</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    MODAL,
    MODAL_MENU,
    SYMBOL_ACTIVE_UPDATE,
    SYMBOL_SHOW,
    MODAL_ASSETS_INFO
} from '@/store/mutations.type'
import {isMobile} from '@/common/helpers'
import {mapGetters} from 'vuex'
import {STATIC_BASE, MARKET_WATCH_DEFAULT_CAT} from "@/common/config"

export default {
    name: 'MarketWatch',
    data() {
        return {
            staticBase: STATIC_BASE,
            marketFilter: '',
            search: '',
            collapsed: {
                forex: MARKET_WATCH_DEFAULT_CAT === 'forex' ? false : true,
                stocks: MARKET_WATCH_DEFAULT_CAT === 'stocks' ? false : true,
                indices: MARKET_WATCH_DEFAULT_CAT === 'indices' ? false : true,
                commodities: MARKET_WATCH_DEFAULT_CAT === 'commodities' ? false : true,
                crypto: MARKET_WATCH_DEFAULT_CAT === 'crypto' ? false : true,
                metals: MARKET_WATCH_DEFAULT_CAT === 'metals' ? false : true,
                nft: MARKET_WATCH_DEFAULT_CAT === 'nft' ? false : true
            },
            time: Date.now(),
        }
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
        showAssetsInfoModal(symbol) {
            this.$store.commit(MODAL_ASSETS_INFO, {
                symbol,
                chartActive: this.chartActive
            })
            this.$store.commit(MODAL, {
                assetsInfo: true,
            })
        },
        changeSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        addChart(symbol) {
            this.$store.commit(SYMBOL_SHOW, {
                symbol,
                frame: 1
            })
        },
        shift(data, time) {
            let shifted = 0
            let tempArray = [].concat(data[0], data[1], data[2], data[3], data[4], data[5], data[6])
            let tempShifted = []
            if (time > 0) {

                for (let i = 0; i < time; i++) {
                    shifted = tempArray.pop()
                    tempShifted[i] = shifted
                }

                for (let i = 0; i < tempShifted.length; i++) {
                    tempArray.unshift(tempShifted[i])
                }
            } else if (time < 0) {
                time = Math.abs(time)
                for (let i = 0; i < time; i++) {
                    shifted = tempArray.shift()
                    tempShifted[i] = shifted
                }

                for (let i = 0; i < tempShifted.length; i++) {
                    tempArray.push(tempShifted[i])
                }
            }

            const chunkArray = (arr, cnt) => arr.reduce((prev, cur, i, a) => !(i % cnt) ? prev.concat([a.slice(i, i + cnt)]) : prev, [])

            let newArray = []
            newArray = chunkArray(tempArray, 24)
            return newArray
        }
    },
    computed: {
        ...mapGetters(['modals', 'tokens', 'symbols', 'sessions', 'sessionsState', 'symbolGroups', 'chartActive', 'charts', 'modalMenu']),
        marketCats() {
            let cats = {
                forex: {
                    name: this.tokens.markets.forex,
                    ico: 'euro_symbol',
                    cnt: 0,
                    symbols: [],
                },
                stocks: {
                    name: this.tokens.markets.stocks,
                    ico: 'account_balance',
                    cnt: 0,
                    symbols: [],
                },
                indices: {
                    name: this.tokens.markets.indices,
                    ico: 'pie_chart',
                    cnt: 0,
                    symbols: [],
                },
                crypto: {
                    name: this.tokens.markets.crypto,
                    ico: 'cloud',
                    cnt: 0,
                    symbols: [],
                },
                commodities: {
                    name: this.tokens.markets.commodities,
                    ico: 'local_fire_department',
                    cnt: 0,
                    symbols: [],
                },
                metals: {
                    name: this.tokens.markets.metals,
                    ico: 'view_stream',
                    cnt: 0,
                    symbols: [],
                },
                nft: {
                    name: this.tokens.markets.nft,
                    ico: 'collections',
                    cnt: 0,
                    symbols: []
                }
            }

            for (let n in this.symbols) {
                let s = this.symbols[n]
                if (cats[s.market] !== undefined) {
                    if (this.search && this.marketFilter !== 'fav' && !s.symbol.toLowerCase().includes(this.search.toLowerCase()) && !s.alias.toLowerCase().includes(this.search.toLowerCase())) {
                        continue
                    }

                    if (this.marketFilter === 'fav' && this.symbolsFavorite.indexOf(s.symbol) === -1) {
                        continue
                    }

                   if (!s.visible) {
                       continue
                   }

                    cats[s.market].symbols.push(s)
                    cats[s.market].cnt++
                }
            }

            return cats
        }
    }
}
</script>

<style scoped>
select {
    margin: 0 15px 15px;
}

.market-item {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--border);
    padding: 8px 13px 8px 15px;
    cursor: pointer;
}

.market-item:nth-child(2) {
    border-top: none
}

.market-item .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 400;
    color: var(--white-to-black)
}

.market-item .name img {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.market-item .symbol {
    zoom: 0.25;
    margin-right: 25px;
}

@-moz-document url-prefix() {
    .market-item .symbol {
        -moz-transform: scale(0.25);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: 0;
    }

    .market-item .name .row:first-child span {
        margin-left: 22px;
    }
}

.market-item .col {
    flex: 1 1 auto;
}

.market-item .smaller {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    font-weight: 400;
    justify-content: space-between;
    margin-left: 10px;
}

.market-item .smaller i {
    font-size: 18px;
}

.market-item .edit {
    font-size: 1em;
    color: var(--white-to-black);
    cursor: pointer;
    margin-left: 5px;
}

.down {
    color: var(--green)
}

.up {
    color: var(--red)
}

.market-item:hover, .market-item.active {
    background: rgba(255, 255, 255, 0.05);
}

.add,
.info {
    cursor: pointer;
    font-size: 1.4em;
    margin: 0 0 0 5px;
    color: var(--font)
}

.info {
    width: 18px;
}

.cat-title {
    display: flex;
    align-items: center;
    background: var(--market-watch-title);
    justify-content: space-between;
    padding: 8px 10px;
    color: var(--white-to-black);
    cursor: pointer;
    border-bottom: 1px solid var(--background-dark);
}

.cat-title i {
    font-size: 1.2em;
    margin: 0 5px 0 3px;
}

.row span {
    font-size: 12px;
}

.row.close, .price.close, .pct.close {
    color: #9f9f9f;
}

.row-2 {
    display: flex;
    align-items: center;
}

.search {
    background: var(--market-watch-title);
    margin: 10px 0 20px;
}

.search-ico {
    position: absolute;
    right: 17px;
    top: 16px;
    font-size: 1.6em;
}
</style>
