<template>
    <div class="terms">
        <div class="content" v-if="langActive === 'sa'" style="text-align: right">
            <h1 class="title">محتوى الموقع بـاللغة العربية</h1>
            <h2>الأصول: ASSETS</h2>
            <p>الصفحة الـرئيسية:</p>
            <p>الحفاظ على عنوان "نظام تداول العقود مقابل الفروقات الاحترافي"</p>
            <p>استبدال المقدمة القديمة بـ "افتح طريقك إلى الاستقلال المالي. بـادر بـالتحرك اليوم واغتنم الفرص المتاحة في
                السوق من خلال مجموعتنا الشاملة من الخدمات والحلول".</p>
            <h2>ما هو الفوركس؟</h2>
            <p>الفوركس، هو اختصار لـمصطلح "الصرف الأجنبي"، وهو سوق عالمي لامركزي لتداول العملات، ويُعتبر السوق المالي
                الأكبر والأكثر سيولة في العالم، حيث يتم شراء وبيع العملات بهدف الاستفادة من تقلبات أسعار الصرف 24 ساعة
                في اليوم، خمسة ايام في الأسبوع.</p>
            <h3>المآخذ الرئيسية:</h3>
            <ul>
                <li>يتعدد المشاركين في سوق الفوركس، ومنهم البنوك والمؤسسات المالية والشركات والحكومات وتجار التجزئة
                    الأفراد الذين يتم إجراؤهم من خلال منصات التداول عبر الانترنت التي يقدمها الوسطاء.
                </li>
                <li>يستخدم المتداولون استراتيجيات مختلفة، بما في ذلك التحليل الفني أو الأساسي أو تحليل المشاعر لاتخاذ
                    قرارات التداول.
                </li>
                <li>يتم التداول خارج البورصة OTC، مما يعني أن المعاملات تتم مباشرة بين المشاركين، ويتم تسهيلها من خلال
                    منصات التداول الإلكترونية.
                </li>
                <li>يمكن أن يوفر تداول الفوركس عوائد عالية ولكنه يجلب أيضا مخاطر عالية.</li>
            </ul>
            <p>الحفاظ على عنوان "فهم سوق الفوركس"</p>
            <h2>أسهم الملكية:</h2>
            <p>عـندما تشتري سهمًا في الشركة، فـإنك تشتري حصة في تلك الشركة، وتصبح مساهمًا فيها. قد يكون للمساهمين الحق
                في التصويت على قرارات الشركة والحصول على على أرباح (إذا كانت الشركة تدفع لهم)، وتتقلب أسعار الأسهم بناء
                على أداء الشركة وظروف السوق ومعنويات المستثمرين.</p>
            <h3>النقاط الرئيسية (حفظ بـالنقاط الثلاثة الأولى، وحذف النقطة الأخيرة – تاريخيًا...)</h3>
            <h3>فهم أسهم الملكية:</h3>
            <p>تعرض الشركات أسهمًا للبيع من أجل تأمين رأس المال، لتلبية احتياجاتها التشغيلية. عندما يكتسب أحد المساهمين
                هذه الأسهم، فإنه يحصل فعليًا على حصة في الشركة.</p>
            <p>اعتمادًا على فئة الأسهم التي يمتلكونها، قد يمتلك المساهمون استحقاقات لجزء من أصول الشركة وأرباحها.
                المساهمين لا يملكون شركات، لكن يمتلكون أسهماً صادرة عن شركات، وهي نوع خاص من التنظيم لأن القانون يعاملهم
                كـأشخاص اعتباريين، وهذا يعني أن الشركة تمتلك أصولها الخاصة.</p>
            <p>التمييز بين أصول الشركة وأصول المساهمين ينشئ فصلًا قانونيًا بينهما، ويعمل على تقييد مسؤولية كل من الشركة
                ومساهميها. في حالة إفلاس الشركة، يجوز للمحكمة أن تأمر ببيع جميع أصول الشركة، ولكن أصولك الشخصية تظل
                محمية. </p>
            <h2>مشتقات:</h2>
            <p>تعتبر المشتقات عقود مالية تعتمد قيمتها على الأصول الأساسية مثل الأسهم والسلع والعملات ومعدلات الفائدة.
                وتعتبر المشتقات أدوات مالية تستخدم لتحقيق عدة أهداف بما في ذلك التحوط ضد المخاطر والاستثمار والتكهن.</p>
            <h3>المآخذ الرئيسية:</h3>
            <ul>
                <li>يمكن استخدام المشتقات لتقليل التعرض للمخاطر من خلال تحوط بعض المخاطر المالية مثل تقلبات الأسعار.
                </li>
                <li>المشتقات متوفرة في أشكال متنوعة مثل العقود الآجلة، والخيارات، والمبادلات.</li>
                <li>بالرغم من أن المشتقات يمكن أن تكون أداة للتحوط ضد المخاطر، فإنها يمكن أيضا أن تكون ذات مخاطر عالية،
                    خاصة في حالات التكهن واستخدام الرافعة المالية.
                </li>
            </ul>
            <h3>فهم المشتقات:</h3>
            <p>المشتقات تقدم مجموعة متنوعة من الاستخدامات، وتشمل التحوط ضد المخاطر والاستثمار في أسواق الأصول والاستفادة
                من فروق الأسعار بين الأسواق. ولكن، يجب أن تكون على دراية بمخاطر المشتقات قبل التداول بها. فهي أدوات
                مالية معقدة تتطلب فهماً جيداً لطبيعة الأصول الأساسية وكيفية تحركات السوق.</p>
            <p>المحتوى الجديد مطلوب في هذا القسم. يمكنك توضيح أكثر عن مختلف أنواع المشتقات وكيفية استخدامها في
                التداول.</p>
            <h2>مشتقات:</h2>
            <p>تعتبر المشتقات عقود مالية تعتمد قيمتها على الأصول الأساسية مثل الأسهم والسلع والعملات ومعدلات الفائدة.
                وتعتبر المشتقات أدوات مالية تستخدم لتحقيق عدة أهداف بما في ذلك التحوط ضد المخاطر والاستثمار والتكهن.</p>

            <h2>فهم البلوكشين:</h2>
            <p>البلوكشين هو نظام لتسجيل المعاملات والتحقق منها بطريقة آمنة ومقاومة للتلاعب. تم استخدام تقنية البلوكشين
                لإدارة سلسلة التوريد، وأنظمة التصويت، وسجلات الرعاية الصحية، والتحقق من الهوية، والمزيد. تتمتع تقنية
                البلوكشين بالقدرة على إنشاء دفاتر رقمية شفافة وآمنة ولديها القدرة على تحويل الصناعات المختلفة عن طريق
                الحد من الاحتيال وتحسين الشفافية وتبسيط العمليات.</p>

            <h2>المعادن:</h2>
            <p>المعادن هي فئة من العناصر التي تتميز ببعض الخصائص الفيزيائية والكيميائية. ولهذه المعادن قيمة جوهرية بسبب
                ندرتها واستخداماتها المتنوعة في مجالات الصناعة والزينة والاستثمار. هناك نوعان من المعادن حول العالم،
                المعادن الأساسية والمعادن الثمينة. المعادن الأساسية، تستخدم في مجموعة متنوعة من التطبيقات التجارية
                والصناعية، ويمكن للمستثمرين والتجار المشاركة في سوق المعادن الأساسية بعدة طرق، وإجراء الاستثمارات في
                الشركات الفردية المتخصصة في إنتاج المعادن الأساسية بشكل خاص، بالإضافة إلى تداول العقود الآجلة وعقود
                الخيارات للمعادن الفردية.</p>
            <p>أما المعادن الثمينة، فهي فئة من السلع التي يتم تداولها إلى جانب العملات والأصول الأخرى في الأسواق
                المالية، ومعروفة بقيمتها الجوهرية وأهميتها التاريخية واستخدامها كمخزن للقيمة. تتوفر مجموعة واسعة من
                أدوات الاستثمار للمهتمين بسوق المعادن الثمينة، حيث يعتبر الذهب استثمارًا قويًا وغالبًا ما يتم الاحتفاظ
                به ماديًا على شكل مجوهرات أو عملات معدنية أو سبائك ذهبية.</p>

            <h2>المؤشرات:</h2>
            <p>ما هو مؤشر السوق؟ مؤشر السوق هو مقياس أو معيار يتتبّع قيمة عملة معينة أو سلة عملات مقارنة بالعملات
                الأخرى. تُستخدم هذه المؤشرات لقياس القوة أو الضعف الإجمالي لعملة معينة أو مجموعة عملات في سوق الصرف
                الأجنبي.</p>

            <h2>أنواع مؤشرات السوق:</h2>
            <ol>
                <li>مؤشر العملة الموحدة: يقيس هذا النوع من مؤشرات العملات قيمة العملة الواحدة مقارنة بسلة من العملات
                    الأخرى.
                </li>
                <li>المؤشر التجاري المرجح: تأخذ المؤشرات التجارية المرجحة بعين الاعتبار التجارة الثنائية بين البلدان
                    وتقيس قيمة العملة مقابل سلة من العملات الأخرى.
                </li>
                <li>المؤشر الجيوسياسي: يتناول هذا النوع من المؤشرات تأثير الأحداث الجيوسياسية على قيمة العملات والأسواق
                    المالية.
                </li>
            </ol>

            <h2>مستقبل الاقتصاد الرقمي:</h2>
            <p>يمكن أن يؤدي التحول نحو الاقتصاد الرقمي إلى تسريع النمو الاقتصادي وزيادة الكفاءة والإنتاجية. ومع ذلك،
                يمكن أن تواجه الحكومات والشركات تحديات متعلقة بالأمان والخصوصية والتكافؤ. على الجانب الآخر، يمكن أن يؤدي
                الاقتصاد الرقمي إلى تحسين الوصول إلى الخدمات المالية وتوسيع فرص الاستثمار وتقديم حلول مالية مبتكرة.</p>
            <p>إضافةأنواع السلع:</p>
            <ol>
                <li>السلع الزراعية: وتشمل المنتجات المزروعة في المزارع، مثل الحبوب (الذرة، الأرز)، والسلع الناعمة
                    (القطن، القهوة)، والماشية (الأبقار، الخنازير).
                </li>
                <li>سلع الطاقة: تشمل هذه الفئة موارد الطاقة مثل النفط الخام والغاز الطبيعي وزيت التدفئة. هذه مكونات
                    حاسمة في إنتاج الطاقة واستهلاكها.
                </li>
                <li>المعادن: تشمل المعادن كلا من المعادن الثمينة (الذهب، البلاتين) والمعادن الصناعية (الألومنيوم،
                    الحديد). وغالباً ما تستخدم المعادن الثمينة كمخزن للقيمة وفي المجوهرات، في حين أن المعادن الصناعية
                    ضرورية للتصنيع والبناء.
                </li>
                <li>الأحجار الكريمة: على الرغم من أنها لا يتم تداولها بشكل شائع مثل السلع الأخرى، إلا أن الأحجار الكريمة
                    مثل الماس والأحجار الكريمة لها أيضًا قيمة ويتم شراؤها وبيعها في السوق العالمية.
                </li>
            </ol>

            <p>إضافةخصائص NFTs:</p>
            <ol>
                <li>غير قابلة للاستبدال: على عكس العملات المشفرة أو العملات التقليدية، فإن NFTs غير قابلة للاستبدال، مما
                    يعني أن كل واحدة منها متميزة ولا يمكن استبدالها على أساس فردي مع NFT أخرى. كل NFT له قيمة فريدة ولا
                    يمكن تكرارها أو استبدالها.
                </li>
                <li>القائم على بلوكشين: عادةً ما يتم إنشاء NFTs وشراؤها وبيعها وامتلاكها باستخدام تقنية بلوكشين، مما
                    يضمن أصالتها ومصدرها. تم بناء معظم NFTs على منصات بلوكشين مثل إيثيريوم.
                </li>
                <li>الملكية الرقمية: تُستخدم الرموز غير القابلة للاستبدال لتمثيل ملكية العناصر الرقمية أو المادية، بما
                    في ذلك الفن الرقمي والموسيقى ومقاطع الفيديو والعقارات الافتراضية والمزيد. إن امتلاك NFT يعني امتلاك
                    شهادة مصادقة رقمية لأصل رقمي أو مادي محدد.
                </li>
                <li>يمكن التحقق منها: يتم تسجيل المعلومات المتعلقة بملكية NFT وتاريخها على بلوكشين، مما يجعلها متاحة
                    للدعاية ويمكن التحقق منها. تساعد هذه الشفافية في إثبات تفرد وأصالة NFTs
                </li>
                <li>حقوق الملكية: غالبًا ما تتضمن ملكية NFT القدرة على إعادة بيع NFT أو عرضه في المعارض الافتراضية أو
                    استخدامه ضمن بيئات أو ألعاب افتراضية محددة.
                </li>
                <li>حقوق ملكية المنشئ: تشتمل العديد من NFTs على عقود ذكية تقوم تلقائيًا بتوزيع الإتاوات على المبدع أو
                    الفنان الأصلي عند إعادة بيع NFT في علامات ثانوية ما يوفر تعويضًا مستمرًا لمنشئي المحتوى.
                </li>
                <li>الأسواق: يتم شراء وبيع NFTs في أسواق NFT، حيث يمكن للمستخدمين تصفح NFTs والمزايدة عليها وشرائها
                    باستخدام العملة المشفرة. تشمل أسواق NFT الشهيرة Open Sea وRarible وNBA Top Shot وغيرها.
                </li>
            </ol>

            <p>"التداول" إليك بعض النصائح:</p>
            <ol>
                <li>التحليل الفني: استخدم التحليل الفني لدراسة تاريخ الأسعار والمؤشرات الفنية لتقديم توقعات محتملة بشأن
                    حركات السعر في المستقبل.
                </li>
                <li>التحليل الأساسي: استخدم التحليل الأساسي لدراسة العوامل الاقتصادية والسياسية والاجتماعية التي يمكن أن
                    تؤثر على قيمة الأصول.
                </li>
                <li>إدارة المخاطر: قد تحدث التقلبات الحادة في الأسواق المالية، لذا يجب مراعاة إدارة المخاطر بحذر للحفاظ
                    على استثماراتك.
                </li>
                <li>خطة التداول: وضع خطة تداول مدروسة تحدد أهدافك وإستراتيجيتك وقواعد إدارة المخاطر الخاصة بك.</li>
                <li>تعليم مستمر: حاول التعلم باستمرار من خلال دراسة الأسواق ومتابعة أحدث الأخبار وتحليلات الخبراء.</li>
                <li>التمرين: قبل الدخول في السوق بشكل كبير، قد تجد أنه من المفيد التمرين من خلال حساب تجريبي لتطبيق
                    معرفتك وتطوير مهاراتك دون خطر الخسارة.
                </li>
            </ol>
            <h1>التجارة: Trading</h1>
            <p>يشير التداول عبر الإنترنت إلى ممارسة شراء وبيع الأصول المالية، مثل الأسهم والسندات والعملات والسلع
                والمشتقات، من خلال منصات على الإنترنت يقدمها الوسطاء والمؤسسات المالية. تتيح طريقة التداول هذه للأفراد
                والمستثمرين المؤسسين الوصول والمشاركة في مختلف الأسواق المالية من خلال أجهزة الكمبيوتر أو الأجهزة
                المحمولة الخاصة بهم.</p>
            <p><strong>المآخذ الرئيسية (احتفظ بها كما هي)</strong></p>
            <h2>استكشاف التداول الفني:</h2>
            <p>التداول الفني، المعروف أيضًا باسم التحليل الفني أو التحليل البياني، هو وسيلة لتقييم واتخاذ قرارات التداول
                في الأسواق المالية بناءً على بيانات الأسعار التاريخية والمؤشرات الإحصائية المختلفة. يفترض هذا النهج أن
                تحركات الأسعار وأحجام التداول السابقة يمكن أن توفر نظرة ثاقبة لتحركات الأسعار المستقبلية. التحدي الذي
                يواجه التحليل الفني هو أن هناك المئات من المؤشرات الفنية المتاحة، ولا يوجد مؤشر واحد يعتبر أفضل عالميًا
                من كل مؤشر معين أو مجموعة من المؤشرات، والتي قد تنطبق فقط على ظروف محددة. هناك مؤشرات فنية مفيدة لبعض
                الصناعات، والبعض الآخر فقط للأسهم ذات تصنيف معين.</p>
            <h2>تداول العقود مقابل الفروقات:</h2>
            <h3>ما هو تداول عقود الفروقات؟</h3>
            <p>عقد الفروقات أو "تداول العقود مقابل الفروقات"، هو شكل شائع من أشكال تداول المشتقات في الأسواق المالية.
                فهو يسمح للمتداولين بالمضاربة على تحركات أسعار الأصول المختلفة دون امتلاك الأصل الأساسي نفسه. وبدلا من
                ذلك، يدخل المتداولون في عقد مع وسيط، ويدفع العقد الفرق في سعر الأصل بين وقت فتح العقد وإغلاقه.</p>
            <p>يحمل تداول العقود مقابل الفروقات مستوى عاليًا من المخاطر بسبب الرافعة المالية، ويمكن أن يخسر المتداولون
                أكثر من استثماراتهم الأولية. علاوة على ذلك، فهو غير متوفر في جميع المناطق، ويمكن أن تختلف اللوائح بشكل
                كبير، لذلك يجب على المتداولين التأكد من امتثالهم للقوانين واللوائح المحلية.</p>
            <p><strong>المأخذ الرئيسية (احتفظ بها كما هي)</strong></p>
            <p>
                <del>حذف (كيف تعمل عقود الفروقات)</del>
            </p>
            <h2>التحليل الفني:</h2>
            <h3>تعريف:</h3>
            <p>التحليل الفني هو وسيلة للتقييم والتنبؤ بتحركات أسعار الأصول المالية من خلال تحليل الرسوم البيانية للأسعار
                التاريخية وأحجام التداول، وهو نهج يستخدم على نطاق واسع في مختلف الأسواق المالية، بما في ذلك الأسهم
                والسلع والفوركس. يعتقد المحللون الفنيون، الذين يشار إليهم غالبًا باسم فناني الرسم البياني، أن بيانات
                الأسعار والحجم التاريخية يمكن أن تساعد في تحديد الأنماط والاتجاهات التي يمكن استخدامها لاتخاذ قرارات
                تداول مستنيرة.</p>
            <p>التحليل الفني له منتقديه، ويرى البعض أنه نهج محدود لأنه لا يأخذ في اعتباره عوامل مثل البيانات المالية
                والأخبار المتعلقة بالشركات. على الرغم من ذلك، يعتبره البعض أداة قوية وفعالة في العثور على نقاط الدخول
                والخروج المثلى في السوق.</p>
            <p><strong>المآخذ الرئيسية (احتفظ بها كما هي)</strong></p>
            <h2>استخدام التحليل الفني</h2>
            <p>يستخدم المحللون المحترفون التحليل الفني جنبًا إلى جنب مع أشكال أخرى من البحث ويتخذون القرارات بناءً على
                الرسوم البيانية لأسعار الأوراق المالية والإحصائيات المماثلة. يحاول التحليل الفني التنبؤ بحركة أسعار أي
                أداة قابلة للتداول تقريبًا والتي تخضع عمومًا لقوة العرض والطلب، بما في ذلك الأسهم والسندات والعقود
                الآجلة وأزواج العملات. ينطبق التحليل الفني بشكل شائع على تغيرات الأسعار، ولكن بعض المحللين يتتبعون
                أرقامًا أخرى غير السعر فقط، مثل حجم التداول أو أرقام الفائدة المفتوحة.</p>

            <h2>التعريف</h2>
            <p>التحليل الأساسي هو طريقة تستخدم في التحليل المالي لتقييم القيمة الجوهرية للأصل. ويتضمن فحص العوامل
                الأساسية التي يمكن أن تؤثر على سعر الأصل. ويهدف إلى تحديد ما إذا كانت قيمة الأصل مبالغ فيها أو أقل من
                قيمتها بالنسبة لسعره الحالي في السوق.</p>

            <h2>فهم التحليل الأساسي</h2>
            <p>عادة ما يتم إجراء التحليل الأساسي من منظور كلي إلى منظور جزئي من أجل تحديد الأوراق المالية التي لم يتم
                تسعيرها بشكل صحيح من قبل السوق، ويستخدم البيانات العامة لتقييم قيمة السهم أو أي نوع آخر من الأوراق
                المالية. بالإضافة إلى استخدامه الإيرادات والأرباح والنمو المستقبلي والعائد على حقوق الملكية وهوامش الربح
                وغيرها من البيانات لتحديد القيمة الأساسية للشركة وإمكانات النمو المستقبلي. ويعتبر التحليل الأساسي هو عكس
                التحليل الفني الذي يتنبأ باتجاه الأسعار من خلال تحليل بيانات السوق التاريخية مثل السعر والحجم.</p>

            <h2>ما هي إدارة المخاطر؟</h2>
            <p>تعد إدارة المخاطر في التداول عبر الإنترنت مجموعة مهمة من الاستراتيجيات والممارسات التي تهدف إلى تقليل
                الخسائر المحتملة والحفاظ على رأس المال أثناء الانخراط في الأسواق المالية، مثل الأسهم أو الفوركس أو السلع
                أو العملات المشفرة. الهدف الأساسي لإدارة المخاطر هو حماية المتداولين والمستثمرين من النكسات المالية
                الكبيرة بسبب الطبيعة المتقلبة وغير المتوقعة للأسواق المالية.</p>

            <h2>العنصر الأساسي في إدارة المخاطر</h2>
            <ol>
                <li>حجم المركز: يعد تحديد الحجم المناسب لمركز التداول بالنسبة إلى إجمالي رأس المال المتداول أمرًا
                    ضروريًا.
                </li>
                <li>أوامر وقف الخسارة: هي تعليمات يتم تقديمها إلى الوسيط لبيع الورقة المالية تلقائيًا عندما تصل إلى
                    مستوى سعر محدد مسبقًا. يستخدم المتداولون وقف الخسارة للحد من خسائرهم المحتملة في التداول.
                </li>
                <li>أوامر جني الأرباح: هو أمر ببيع ورقة مالية عندما تصل إلى مستوى ربح محدد مسبقًا، مما يتيح للمتداولين
                    الاحتفاظ بأرباحهم قبل أن ينخرط السوق في عمليات بيع جنائزية محتملة.
                </li>
                <li>استخدام الرافعة المالية بحكمة: يجب أن يتجنب المتداولون استخدام الرافعة المالية بشكل كبير، حيث يمكن
                    أن تؤدي إلى خسائر كبيرة.
                </li>
            </ol>

            <h2>استراتيجيات التداول الأكثر شهرة</h2>
            <p>تشمل الاستراتيجيات التجارية الشائعة استراتيجيات التداول بالاتجاه، والتداول على أساس النطاق، واستراتيجيات
                التداول على أساس الأخبار، واستراتيجيات التداول الكمي. وتهدف هذه الاستراتيجيات إلى مساعدة المتداولين على
                الاستفادة من تحركات السوق المختلفة بناءً على أنماط الأسعار والتحليل الفني والأحداث الاقتصادية والأخبار
                وغيرها من العوامل.</p>
            <h2>القوانين Legal</h2>

            <h2>خصوصية</h2>
            <p>تحكم سياسة الخصوصية هذه الطريقة التي تقوم بها شركة FXA-Trading بجمع واستخدام والحفاظ على والكشف عن
                المعلومات التي تم جمعها من مستخدمي موقع mapledex.ca . تنطبق سياسة الخصوصية هذه على موقع FXA-Trading
                وجميع المنتجات والخدمات التي تقدمها الشركة.</p>

            <h2>معلومات التعريف الشخصية</h2>
            <p>عندما يتصفح المستخدمون موقع الويب، أو ينشئون حسابًا عليه، أو يشتركون في نشرتنا الإخبارية، أو يشاركون في
                الأنشطة أو الخدمات أو الميزات أو الموارد الأخرى المتوفرة على الموقع، اعتمادًا على السياق، قد يُطلب من
                المستخدمين تقديم معلومات مثل الاسم أو عنوان البريد الإلكتروني أو العنوان البريدي أو رقم الهاتف أو تفاصيل
                بطاقة الائتمان.</p>

            <h2>ملفات تعريف الارتباط لمتصفح الويب</h2>
            <p>يقوم متصفح الويب الخاص بالمستخدم بوضع ملفات تعريف الارتباط على محرك الأقراص الثابتة الخاص به لأغراض حفظ
                السجلات وفي بعض الأحيان لتتبع المعلومات المتعلقة به. يمكن للمستخدم اختيار ضبط متصفح الويب الخاص به لرفض
                ملفات تعريف الارتباط، أو لتنبيهك عند إرسال ملفات تعريف الارتباط.</p>

            <h2>الغرض من استخدام معلومات العميل</h2>
            <ul>
                <li>تحسين طلبات خدمة العملاء واحتياجات الدعم.</li>
                <li>تخصيص تجربة المستخدم للاستفادة من الخدمات والموارد المقدمة على موقعنا.</li>
                <li>تحسين عروض موقعنا الإلكتروني بناءً على المعلومات والتعليقات التي نتلقاها منك.</li>
                <li>معالجة المعاملات.</li>
                <li>إدارة مسابقة، ترويج، استطلاع، أو أي ميزة أخرى بالموقع.</li>
                <li>إرسال المعلومات للمستخدمين الذين وافقوا على تلقيها حول المواضيع التي نعتقد أنها ستكون ذات فائدة
                    لهم.
                </li>
                <li>إرسال رسائل إلكترونية دورية والرد على استفساراتهم، أو طلباتهم الأخرى.</li>
            </ul>

            <h2>كيف نحمي معلوماتنا؟</h2>
            <p>ننفذ الممارسات المناسبة لجمع البيانات وتخزينها ومعالجتها، إلى جانب بروتوكولات الأمان، لحماية مستخدمينا من
                أي وصول غير مصرح به أو تعديل أو إفشاء أو فقدان لمعلوماتهم الشخصية والبيانات المخزنة على منصتنا.</p>
            <p>يتم تبادل البيانات الحساسة والخاصة بين موقعنا ومستخدميه من خلال قناة اتصال مؤمنة بواسطة SSL، مما يضمن
                التشفير والحماية عبر التوقيعات الرقمية.</p>
            <p>لا نجمع معلومات على موقعنا من الأفراد الذين نعلم أنهم دون ال 18 عامًا.</p>

            <h2>تعديلات على سياسة الخصوصية هذه</h2>
            <p>يجوز لشركة FXA-Trading مراجعة وتحديث سياسة الخصوصية هذه من وقت لآخر. وعندما نقوم بذلك، سوف نحدث التاريخ
                الموجود في أسفل هذه الصفحة. نشجع المستخدمين على التحقق بشكل دوري من هذه الصفحة لأي تغييرات، ليظلوا على
                علم بكيفية نحافظ على معلوماتهم الشخصية.</p>
            <h2>التجمع واستخدام المعلومات الشخصية</h2>
            <p>عندما يتصفح المستخدمون موقع الويب، أو ينشئون حسابًا عليه، أو يشتركون في نشرتنا الإخبارية، أو يشاركون في
                الأنشطة أو الخدمات أو الميزات أو الموارد الأخرى المتوفرة على الموقع. اعتمادًا على السياق، قد يُطلب من
                المستخدمين تقديم معلومات مثل الاسم أو عنوان البريد الإلكتروني أو العنوان البريدي أو رقم الهاتف أو تفاصيل
                بطاقة الائتمان.</p>

            <h2>ملفات تعريف الارتباط لمتصفح الويب</h2>
            <p>يقوم متصفح الويب الخاص بالمستخدم بوضع ملفات تعريف الارتباط على محرك الأقراص الثابتة الخاص به لأغراض حفظ
                السجلات وفي بعض الأحيان لتتبع المعلومات المتعلقة بها. يمكن للمستخدم اختيار ضبط متصفح الويب الخاص به لرفض
                ملفات تعريف الارتباط، أو لتنبيهك عند إرسال ملفات تعريف الارتباط.</p>

            <h2>الغرض من استخدام معلومات العميل</h2>
            <ul>
                <li>تحسين طلبات خدمة العملاء واحتياجات الدعم.</li>
                <li>تخصيص تجربة المستخدم للاستفادة من الخدمات والموارد المقدمة على موقعنا.</li>
                <li>تحسين عروض موقعنا الإلكتروني بناءً على المعلومات والتعليقات التي نتلقاها منك.</li>
                <li>معالجة المعاملات.</li>
                <li>إدارة مسابقة، ترويج، استطلاع، أو أي ميزة أخرى بالموقع.</li>
                <li>إرسال المعلومات للمستخدمين الذين وافقوا على تلقيها حول المواضيع التي نعتقد أنها ستكون ذات فائدة
                    لهم.
                </li>
                <li>إرسال رسائل إلكترونية دورية والرد على استفساراتهم، أو طلباتهم الأخرى.</li>
            </ul>

            <h2>كيف نحمي معلوماتنا؟</h2>
            <p>ننفذ الممارسات المناسبة لجمع البيانات وتخزينها ومعالجتها، إلى جانب بروتوكولات الأمان، لحماية مستخدمينا من
                أي وصول غير مصرح به أو تعديل أو إفشاء أو فقدان لمعلوماتهم الشخصية والبيانات المخزنة على منصتنا.
                يتم تبادل البيانات الحساسة والخاصة بين موقعنا ومستخدميه من خلال قناة اتصال مؤمنة بواسطة SSL، مما يضمن
                التشفير والحماية عبر التوقيعات الرقمية.
                لا نجمع معلومات على موقعنا من المستخدمين الذين تقل أعمارهم عن 18 عامًا، ولم يتم تصميم أي جزء من موقعنا
                لجذب أي شخص أقل من 18 عامًا.
                نشجع المستخدمين على مراجعة هذه الصفحة بشكل متكرر بحثًا عن أي تغييرات للبقاء على اطلاع حول كيفية مساعدتنا
                في حماية المعلومات الشخصية التي نجمعها.</p>
            <p>قم بالإشارة إلى موافقتك على هذه السياسة وتحديثاتها عن طريق استخدام الموقع.</p>
            <h1>ما هو غسيل الأموال؟</h1>
            <p>
                يشمل غسيل الأموال العديد من الإجراءات التي تنطوي على أموال غير مشروعة، مثل التعامل مع العائدات
                الإجرامية، ومساعدة الآخرين في نقل هذه الأموال، والتعامل مع الاحتيال أو الأموال المرتبطة بالفساد.
                ويشمل الأصول الملموسة وغير الملموسة وينطوي على مراحل مثل وضع الأموال النقدية غير المشروعة في حساب مصرفي،
                ثم تمريرها عبر معاملات معقدة لإخفاء مصدرها، وأخيرا دمجها في الاقتصاد المشروع لجعلها تبدو قانونية.
                ويغطي غسيل الأموال أيضًا أنشطة تمويل الإرهاب، وتؤكد السياسة على اليقظة في تحديد محاولات غسيل الأموال
                المحتملة من قبل العملاء أو شركائهم عند استخدام خدماتهم.
            </p>

            <h2>الضوابط</h2>
            <p>
                هناك العديد من الضوابط الداخلية، بما في ذلك رفض قبول المدفوعات النقدية وودائع الطرف الثالث أو عمليات
                الاسترداد. بالإضافة إلى ذلك، لا تقوم الشركة باستقبال العملاء من البلدان أو المنظمات المقيدة أو الخاضعة
                للعقوبات، وتشمل النقاط المرجعية لمثل هذه المراجعات المواقع الإلكترونية التالية:
            </p>
            <ul>
                <li>وزارة الخارجية والتجارة الأسترالية</li>
                <li>قوائم الأمم الموحدة</li>
                <li>مكتب الولايات المتحدة لمراقبة الأصول الأجنبية</li>
                <li>مجموعة العمل المالي</li>
            </ul>
            <p>
                لا نقبل المواطنين الأمريكيين, الحسابات المجهولة المصدر، ولا نقيم أي علاقات مع البنوك الوهمية.
            </p>

            <h2>الاحتفاظ بـ عنوان "معلومات العميل المطلوبة ومعلومات التحقق"</h2>
            <h2>الاحتفاظ بـ عنوان "الأداء من قبل أطراف ثالثة"</h2>

            <h2>المراقبة المستمرة:</h2>
            <p>
                تلعب المراقبة المستمرة دورًا مهمًا كجزء أساسي من إجراءات "اعرف عميلك" الفعالة، ويسلط الضوء على أن فهم
                نشاط حساب العميل العادي أمر ضروري لتحديد المعاملات غير العادية التي قد تشير إلى نشاط مشبوه، وبدون هذه
                المعرفة، قد تتعرض التزامات الإبلاغ للخطر.
                إن عملية المراقبة حساسة للمخاطر، ويتم وضع أنظمة للكشف عن الأنماط غير العادية، مع التركيز على المعاملات
                التي تتجاوز الحدود المقررة. وتثير أنواع محددة من المعاملات، مثل الودائع النقدية الكبيرة أو تلك التي
                تفتقر إلى المنطق الاقتصادي الشكوك.
                تخضع الحسابات عالية المخاطر لمراقبة مكثفة، مع الأخذ في الاعتبار عوامل مثل خلفية العميل وأنواع المعاملات
                وعناصر المخاطر.
            </p>
            <div>
                <h2>الإبلاغ عن الأنشطة المشبوهة:</h2>
                <p>يتم الإبلاغ عن أي نشاط مشبوه لغسل الأموال وتمويل الإرهاب إلى السلطات المختصة، بعد التحقيق.</p>
                <p>تتضمن أمثلة الأنشطة المشبوهة، على سبيل المثال، ما يلي:</p>
                <ul>
                    <li>نشاط خارج عن المألوف في الحساب.</li>
                    <li>خارج النطاق المتوقع لأحجام المعاملات أو الحدود المالية.</li>
                    <li>عند الطلب، يرفض العميل تحديد أو يفشل في الإشارة إلى أي مصدر شرعي لأمواله وأصوله الأخرى.</li>
                    <li>يتمتع العميل بخلفية مشكوك فيها أو يكون موضوعًا لتقارير إخبارية تشير إلى احتمال حدوث انتهاكات
                        جنائية أو مدنية أو تنظيمية.
                    </li>
                </ul>

                <h2>حفظ السجلات:</h2>
                <p>يتم تخزين معلومات العملاء بشكل آمن لمدة خمس سنوات باستخدام التكنولوجيا المتقدمة. تبدأ فترة الاحتفاظ
                    هذه بعد المعاملات أو انتهاء علاقة العمل.</p>
                <p>يتم الاحتفاظ بالسجلات التالية:</p>
                <ul>
                    <li>المواد الثبوتية لهوية العميل</li>
                    <li>العلاقات التجارية والمعاملات، بما في ذلك وثائق تسجيل المعاملات في دفاتر المحاسبة</li>
                    <li>وثائق المراسلات ذات الصلة مع العملاء والأشخاص الآخرين الذين تربطهم بهم علاقة عمل</li>
                </ul>

                <h2>تدريب الموظفين:</h2>
                <p>تجري الشركة فحوصات شاملة لخلفية الموظفين أثناء التوظيف وتوفر التدريب المستمر, ويتم تعليم الموظفين
                    الجدد أهمية سياسات "اعرف عميلك" والمتطلبات الأساسية. يتم تدريب الموظفين الذين يتعاملون مع العملاء
                    بشكل مباشر على التحقق من هويات العملاء، وممارسة العناية الواجبة المستمرة مع الحسابات الحالية، وتحديد
                    أنماط الأنشطة المشبوهة، بـالإضافة إلى تعزيز مسؤوليات الموظفين وإبقائهم على اطلاع دائم بتطورات
                    الصناعة.</p>

                <h2>المراجعات:</h2>
                <p>تقوم شركات التدقيق ذات السمعة الطيبة بإجراء عمليات تدقيق منتظمة لضمان الامتثال للسياسات والإجراءات،
                    وتتم مراجعة هذه السياسات بانتظام لتتوافق مع اللوائح الحالية والعوامل الخارجية.</p>
                <p>إذا كان لديك أي أسئلة، فلا تتردد في الاتصال بنا باستخدام تفاصيل الاتصال على موقعنا.</p>

                <p>حذف (إشعار معلومات إضافية)</p>

                <h2>معلومات عنا:</h2>
                <p>FXA-Trading هي شركة وساطة عالمية ملتزمة بتوفير بيئة تداول متعددة الاستخدامات يمكن الوصول إليها لجميع
                    مستويات المتداولين، من المبتدئين إلى المحترفين. توفر منصتنا مجموعة واسعة من الأصول عالية السيولة،
                    مما يتيح للمتداولين التداول والاستثمار وفقًا لتفضيلاتهم وأهدافهم.</p>
                <p>"عميلنا يأتي أولاً"، نحن نقدم منصة تداول آمنة ومتقدمة، مع إعطاء الأولوية لخدمة العملاء الاستثنائية مع
                    دعم على مدار الساعة.</p>
            </div>
        </div>
        <div class="content" v-else>
            <h1 class="title">Professional CFD Trading System</h1>

            <h2>ASSETS:</h2>

            <h2>What is Forex?</h2>
            <p>Forex, short for “foreign exchange”, is a global decentralized marketplace for the trading of
                currencies. It's the largest and most liquid financial market in the world, where currencies are
                bought and sold with the aim of profiting from fluctuations in exchange rates, 24 hours a day, five
                days a week.</p>

            <h3>Key Takeaways:</h3>
            <ul>
                <li>The forex market is comprised of various participants, including banks, financial institutions,
                    corporations, governments, and individual retail traders conducted through online trading
                    platforms provided by brokers.
                </li>
                <li>Traders use various strategies, including technical, fundamental, or sentiment analysis to make
                    trading decisions.
                </li>
                <li>Trading occurs over-the-counter (OTC), meaning transactions are conducted directly between
                    participants, facilitated by electronic trading platforms.
                </li>
                <li>Forex trading can provide high returns but also brings high risk.</li>
            </ul>

            <h2>Understanding The Forex Market:</h2>
            <p>Forex, short for “foreign exchange”, is a global decentralized marketplace for the trading of
                currencies. It's the largest and most liquid financial market in the world, where currencies are
                bought and sold with the aim of profiting from fluctuations in exchange rates, 24 hours a day, five
                days a week.</p>

            <h3>Key Takeaways:</h3>
            <ul>
                <li>The forex market is comprised of various participants, including banks, financial institutions,
                    corporations, governments, and individual retail traders conducted through online trading
                    platforms provided by brokers.
                </li>
                <li>Traders use various strategies, including technical, fundamental, or sentiment analysis to make
                    trading decisions.
                </li>
                <li>Trading occurs over-the-counter (OTC), meaning transactions are conducted directly between
                    participants, facilitated by electronic trading platforms.
                </li>
                <li>Forex trading can provide high returns but also brings high risk.</li>
            </ul>

            <h2>Stocks:</h2>
            <p>Stocks represent ownership in a publicly-traded company. When you buy a stock, you are buying a share
                of that company, and you become a shareholder. Shareholders may have the right to vote on company
                decisions and receive dividends (if the company pays them). Stock prices fluctuate based on the
                company's performance, market conditions, and investor sentiment.</p>

            <h3>Key takeaways:</h3>

            <h2>Understanding Stocks:</h2>
            <p>Companies offer shares of stock for sale in order to secure capital for their operational needs. When
                a shareholder, acquires these shares, they effectively obtain a stake in the company. Depending on
                the class of shares they hold, shareholders may possess entitlements to a portion of the company's
                assets and profits.</p>
            <p>Stockholders do not own corporations; they own shares issued by corporations, which are a special
                type of organization because the law treats them as legal persons, and this means that the
                corporation owns its own assets.</p>
            <p>Differentiation between corporation's assets and those of its shareholders establishes a legal
                separation between them. This separation serves to restrict the liability of both the corporation
                and its shareholders. In the unfortunate event of corporate bankruptcy, a court may mandate the sale
                of all corporate assets, but your personal assets remain safeguarded. Similarly, if a prominent
                shareholder faces bankruptcy, they cannot liquidate the company's assets to settle their debts.</p>

            <h2>Crypto:</h2>
            <h3>What is Crypto Currencies?</h3>
            <p>Cryptocurrencies are digital or virtual currencies that use cryptography for security and not issued
                by any central authority. Unlike traditional currencies issued by governments and central banks.
                They are decentralized and typically operate on a technology called blockchain.</p>

            <h3>Key takeaways:</h3>
            <ul>
                <li>Cryptocurrencies exist only in digital form and operate on decentralized networks of computers,
                    which means they are not controlled by a single entity like a central bank.
                </li>
                <li>Many cryptocurrencies have a limited supply, meaning there is a maximum number of coins that can
                    ever be created.
                </li>
                <li>Cryptocurrencies employ cryptographic techniques to secure transactions and control the creation
                    of new units. This makes it difficult for unauthorized parties to alter transaction data or
                    counterfeit coins.
                </li>
                <li>The advantages of cryptocurrencies include cheaper and faster money transfers. While its
                    disadvantages include their price volatility, high energy consumption for mining or criminal
                    activities.
                </li>
            </ul>

            <h2>Understanding Blockchain:</h2>
            <p>Blockchain is a system for recording and verifying transactions in a secure and tamper-resistant
                manner.</p>
            <p>Blockchain technology has been used for supply chain management, voting systems, healthcare records,
                identity verification, and more.</p>
            <p>Blockchain technology has the ability to create transparent and secure digital ledgers has the
                potential to transform various industries by reducing fraud, improving transparency, and
                streamlining processes.</p>

            <h2>Metals:</h2>
            <p>Metals are a category of chemical elements characterized by certain physical and chemical properties.
                These metals have intrinsic value due to their rarity and various industrial, ornamental, and
                investment uses.</p>
            <p>There are two types of metals around the world, the base and the precious metals.</p>
            <p>Base metals are generally plentiful and are used in a variety of commercial and industrial
                applications.
                Investors and traders can participate in the base metals market in several ways.
                Investments can be made in individual companies specializing in particular base metals production.
                Futures and options contracts of individual metals can be traded.</p>

            <p>Precious metals are a category of commodities that are traded alongside currencies and other assets
                in the financial markets.
                They are known for their intrinsic value, historical significance, and use as stores of value.
                A wide range of investment vehicles are available to those interested in the precious metals market.
                Gold is considered a solid investment and is often physically held in the form of jewelry, coins, or
                gold bars.</p>

            <h2>Indices:</h2>
            <h3>What is a market index?</h3>
            <p>A currency index is a measure or benchmark that tracks the value of a specific currency or a basket
                of currencies relative to other currencies. These indices are used to gauge the overall strength or
                weakness of a particular currency or group of currencies in the foreign exchange market.</p>
            <h2>Markets Index Types</h2>
            <ul>
                <li>
                    <strong>Single Currency Index:</strong> This type of currency index measures the value of a single
                    currency relative to a basket of other currencies.
                </li>
                <li><strong>Trade Weighted Index:</strong> Trade - weighted indices take into account a country's
                    trading partners and assign weights to currencies based on the volume of trade with those partners.
                    These indices provide a more realistic picture of a currency's performance in the global trade
                    context.
                </li>
                <li><strong>Major Currency Index</strong> A major currency index typically track the performance of a
                    group of major currencies, excluding the currency of the country where the index is calculated
                </li>
            </ul>
            <h2>Commodities:</h2>
            <h3>Definition of Commodities:</h3>
            <p>Commodities refer to raw materials or primary agricultural products that can be bought and sold,
                typically in standardized units of measure, in the global marketplace. These goods are typically
                interchangeable with other commodities of the same type and quality.
                Commodity trading is not limited to physical goods; it also includes financial instruments that
                derive their value from underlying commodities, such as commodity futures contracts, options, and
                commodity-based exchange-traded funds (ETFs).
                Commodity prices can be influenced positively or negatively by a wide range of factors, including
                supply and demand dynamics, geopolitical events, weather conditions, currency fluctuations, and
                economic trends.</p>

            <h2>Types of Commodities:</h2>
            <ul>
                <li>Agricultural Commodities: These include products grown on farms, such as grains (corn, rice),
                    soft commodities (cotton, coffee), and livestock (cattle, hogs).
                </li>
                <li>Energy Commodities: This category encompasses energy resources like crude oil, natural gas, and
                    heating oil. These are crucial components in energy production and consumption.
                </li>
                <li>Metals: Metals include both precious metals (gold, platinum) and industrial metals (aluminum,
                    iron). Precious metals are often used as stores of value and in jewelry, while industrial metals
                    are essential for manufacturing and construction.
                </li>
                <li>Precious stones: While not as commonly traded as other commodities, precious stones like
                    diamonds and gemstones also have value and are bought and sold in the global market.
                </li>
            </ul>

            <h2>NFT:</h2>
            <p>Non-Fungible Token OR NFT is a type of digital asset that represents ownership or proof of
                authenticity of a unique item or piece of content using blockchain technology. It has gained
                significant attention and popularity, particularly in the world of digital art, collectibles,
                gaming, and entertainment.</p>

            <h2>NFTs Characteristics:</h2>
            <ul>
                <li><strong>Non-Fungible:</strong> Unlike crypto or traditional currencies, NFTs are non-fungible, which
                    means that
                    each one is distinct and can’t be exchanged on a one-to-one basis with another NFT. Each NFT has
                    a unique value and can’t be replicated or replaced.
                </li>
                <li><strong>Blockchain-Based:</strong> NFTs are typically created, bought, sold, and owned using
                    blockchain
                    technology, which ensures their authenticity and provenance. Most NFTs are built on blockchain
                    platforms like Ethereum.
                </li>
                <li><strong>Digital Ownership:</strong> NFTs are used to represent ownership of digital or physical
                    items, including
                    digital art, music, videos, virtual real estate, and more. Owning an NFT means owning a digital
                    certificate of authenticity for a specific digital or physical asset.
                </li>
                <li><strong>Verifiable:</strong> The information related to NFT ownership and history is recorded on the
                    blockchain,
                    making it publicly accessible and verifiable. This transparency helps in establishing the
                    uniqueness and authenticity of NFTs.
                </li>
                <li><strong>Ownership Rights:</strong> NFT ownership often includes the ability to resell the NFT,
                    showcase it in
                    virtual galleries, or use it within specific virtual environments or games.
                </li>
                <li><strong>Creator Royalties:</strong> Many NFTs incorporate smart contracts that automatically
                    distribute royalties
                    to the original creator or artist whenever the NFT is resold in secondary markets. This provides
                    ongoing compensation to content creators.
                </li>
                <li><strong>Marketplaces:</strong> NFTs are bought and sold on NFT marketplaces, where users can browse,
                    bid on, and
                    purchase NFTs using
                    cryptocurrency. Popular NFT marketplaces include Open Sea, Rarible, and NBA Top Shot, among
                    others.
                </li>
            </ul>

            <h2>FAQ:</h2>
            <p>Trade Your Way to Success with FXA-Trading!</p>

            <h2>Trading:</h2>
            <p>Online trading refers to the practice of buying and selling financial assets, such as stocks, bonds,
                currencies, commodities, and derivatives, through internet-based platforms provided by brokers and
                financial institutions. This method of trading allows individuals and institutional investors to
                access and participate in various financial markets from the convenience of their computers or
                mobile devices.</p>

            <h2>Exploring Technical Trading:</h2>
            <p>Technical trading, also known as technical or chart analysis, is a method of evaluating and making
                trading decisions in financial markets based primarily on historical price data and various
                statistical indicators. This approach assumes that past price movements and trading volumes can
                provide insights into future price movements.</p>

            <p>The challenge of technical analysis is that there are hundreds of technical indicators available, and
                there is no single indicator that is considered universally better as each particular indicator or
                group of indicators may be applicable only to specific circumstances. Some technical indicators may
                be useful for certain industries, others only for stocks of a certain classification.</p>

            <h2>CFD Trading:</h2>
            <h3>What is CFD Trading?</h3>
            <p>Contract for Difference or “CFD trading”, is a popular form of derivative trading in financial
                markets. It allows traders to speculate on the price movements of various assets without owning the
                underlying asset itself. Instead, traders enter into a contract with a broker, and the contract pays
                the difference in the asset's price between the time the contract is opened and closed.</p>

            <p>CFD trading carries a high level of risk due to leverage, and traders can lose more than their
                initial investment. Moreover, it is not available in all regions, and regulations can vary
                significantly, so traders should ensure they are compliant with local laws and regulations.</p>

            <h2>Technical Analysis:</h2>
            <h3>Definition:</h3>
            <p>Technical analysis is a method of evaluating and predicting financial asset price movements by
                analyzing historical price charts and trading volumes. It is a widely used approach in various
                financial markets, including stocks, commodities, and Forex. Technical analysts, often referred to
                as chartists, believe that historical price and volume data can help identify patterns and trends
                that can be used to make informed trading decisions.</p>

            <p>Technical analysis has its critics, and some view it as subjective and reliant on historical data.
                Traders often use a combination of technical analysis and fundamental analysis (which considers
                economic, financial, and company-specific factors) to make informed trading decisions.</p>

            <h2>Fundamental Analysis:</h2>
            <p>Fundamental Analysis is a method used in financial analysis to evaluate the intrinsic value of an
                asset. It involves examining the underlying factors that could influence the asset's price. It aims
                to determine whether an asset is overvalued or undervalued in relation to its current market
                price.</p>
            <h3>Understanding Fundamental Analysis:</h3>
            <p>Fundamental analysis is usually done from a macro to micro perspective in order to identify
                securities that are not correctly priced by the market.</p>
            <p>It uses public data to evaluate the value of a stock or any other type of security.</p>
            <p>It uses revenues, earnings, future growth, return on equity, profit margins, and other data to
                determine a company's underlying value and potential for future growth.</p>
            <p>Fundamental analysis is considered to be the opposite of technical analysis, which forecasts the
                direction of prices through an analysis of historical market data such as price and volume.</p>
            <h2>Risk Management:</h2>
            <p>What is Risk Management?</p>
            <p>Risk management in online trading is a crucial set of strategies and practices aimed at minimizing
                potential losses and preserving capital while engaging in financial markets, such as stocks, Forex,
                commodities, or cryptocurrencies. The primary goal of risk management is to protect traders and
                investors from significant financial setbacks due to the inherently volatile and unpredictable nature of
                financial markets.
            </p>
            <h2>Key Element in Risk Management</h2>
            <ul>
                <li>Position Sizing: Determining the appropriate size of a trading position relative to the total
                    trading capital is essential.
                </li>
                <li>Stop-loss Orders: is an instruction placed with a broker to automatically sell a security when it
                    reaches a predetermined price level. Traders use stop-loss to limit their potential losses on a
                    trade.
                </li>
                <li>Take-Profit Orders: is an order to sell a security when it reaches a predetermined profit level. It
                    helps traders lock in gains and avoid the temptation of letting profitable trades turn into losses
                    due to greed or indecision.
                </li>
                <li>Use of Leverage: If leverage is available in a trading account, it should be used cautiously. While
                    leverage can amplify profits, it can also magnify losses.
                </li>
                <li>Emotional Control: Effective risk management includes maintaining emotional discipline, sticking to
                    a trading plan, and not letting fear or greed dictate trading decisions.
                </li>
            </ul>

            <h2>Legal:</h2>
            <h3>Privacy:</h3>
            <p>This Privacy Policy governs the manner in which FXA-Trading collects, uses, maintains and discloses
                information collected from users of the FXA-Trading website (mapledex.ca). This privacy policy
                applies to the Site and all products and services offered by the company.</p>

            <h2>Personal Identification Information:</h2>
            <p>When Users browse the Website, create an account on it, subscribe to our newsletter, or engage in
                other
                activities, services, features, or resources provided on the Website. Depending on the context,
                Users
                may be requested to provide information such as their name, email address, mailing address, phone
                number, or credit card details.</p>

            <h2>Web Browser Cookies:</h2>
            <p>User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to
                track
                information about them. User may choose to set their web browser to refuse cookies, or to alert you
                when
                cookies are being sent.</p>

            <h2>Purpose for using customer’s information:</h2>
            <ul>
                <li>To improve customer service requests and support needs.</li>
                <li>To personalize user experience and how is he us the services and resources provided on our
                    Site.
                </li>
                <li>To improve our website offerings based on the information and feedback we receive from you.</li>
                <li>To process transactions.</li>
                <li>To administer a contest, promotion, survey or other Site feature.</li>
                <li>To send Users information they agreed to receive about topics we think will be of interest to
                    them.
                </li>
                <li>To send periodic emails and respond to their inquiries, or other requests.</li>
            </ul>

            <h2>How We Protect Our Information?</h2>
            <p>We implement suitable data gathering, storage, and processing practices, along with security
                protocols, to safeguard our Users from any unauthorized access, modification, disclosure, or loss of
                their personal information, and data stored on our platform. </p>
            <p>The exchange of sensitive and private data between our site and its Users is carried out through an
                SSL-secured communication channel, ensuring encryption and protection via digital signatures.</p>
            <p>We never collect information at our site from users under 18, and no part of our website is
                structured to attract anyone under 18.</p>
            <p>We encourage users to frequently check this page for any changes to stay informed about how we are
                helping to protect the personal information we collect.</p>
            <p>Signify your acceptance of this policy and terms of service. If you don’t agree on it, please don’t
                use our site.</p>

            <h2>KYC: Know Your Customer</h2>
            <p>FXA-Trading is fully committed to complying with anti-money laundering (AML) and counter-funding of
                terrorism (CTF) regulations. They’ve developed a policy based on legal principles and directives to
                combat money laundering effectively. Their belief is that adhering to these regulations is crucial
                for financial system integrity and to prevent their services from being exploited by criminal
                elements.</p>
            <p>To meet these requirements, FXA-Trading has established Know Your Customer (KYC) programs as a vital
                part of their services, risk management, and control procedures. This includes verifying the
                identity of clients, monitoring account activities, and providing AML training. This comprehensive
                approach ensures compliance with AML and CFT regulations while safeguarding the integrity of their
                financial service.</p>

            <h2>What is Money Laundering?</h2>
            <p>Money laundering encompasses various actions involving illicit funds, such as handling criminal
                proceeds, assisting others in moving such funds, and dealing with fraud or corruption-related money.
                It includes both tangible and intangible assets and involves stages like placing illicit cash into a
                bank account, then layering it through complex transactions to obscure its origin, and finally
                integrating it into the legitimate economy to make it appear legal.</p>
            <p>Money laundering also covers terrorist financing activities. The policy emphasizes vigilance in
                identifying potential money laundering attempts by customers or their associates when using their
                services.</p>

            <h2>Controls</h2>
            <p>Several internal controls, including the refusal to accept cash payments and third-party deposits or
                redemptions. Additionally, the company does not onboard customers from restricted or sanctioned
                countries or organizations, Reference points for such reviews include the websites of:
            </p>
            <ul>
                <li>Australian Department of Foreign Affairs and Trade,</li>
                <li>Nations Consolidated Lists,</li>
                <li>US Office of Foreign Assets Control,</li>
                <li>FATF.</li>
            </ul>
            <p>We do not accept US Citizens.</p>
            <p>We do not accept any anonymous accounts and do not maintain any relationships with shell banks.</p>

            <h2>ON-Going Monitoring</h2>
            <p>The ongoing monitoring plays an important role as a crucial part of effective Know Your Customer
                (KYC) procedures. It highlights that understanding normal customer account activity is essential for
                identifying unusual transactions that might indicate suspicious activity, and without this
                knowledge, reporting obligations may be compromised.</p>
            <p>The monitoring process is risk-sensitive, and systems are in place to detect unusual patterns, with a
                focus on transactions exceeding established limits. Specific types of transactions, such as large
                cash deposits or those lacking economic sense, raise suspicions.</p>
            <p>Higher-risk accounts undergo intensified monitoring, considering factors like customer background,
                transaction types, and risk elements.</p>

            <h2>Reporting Suspicious Activity</h2>
            <p>In line with the relevant regulations any suspicious activity of money laundering and funding of
                terrorism is reported to the relevant authorities, after investigation. Examples of suspicious
                activity include but are not limited to:</p>
            <ul>
                <li>Out-of-the-ordinary account activity.</li>
                <li>Out of expected range of transaction volumes or financial thresholds.</li>
                <li>The customer refuses to identify or fails to indicate any legitimate source for his funds and
                    other assets.
                </li>
                <li>The customer has a questionable background or is the subject of news reports indicating possible
                    criminal, civil, or regulatory violations.
                </li>
                <li>The customer exhibits a lack of concern regarding risks, commissions, or other transaction
                    costs.
                </li>
            </ul>

            <h2>Record Keeping</h2>
            <p>Customer information is securely stored for five years using advanced technology. This retention
                period starts after transactions or the end of the business relationship. This includes keeping
                customer identification papers and financial transaction records for at least five years after an
                account is closed or the client relationship ends. Confidentiality is maintained in compliance with
                the law.</p>
            <p>The following records are kept:</p>
            <ul>
                <li>Copies of the evidential material of the customer identity;</li>
                <li>Relevant evidential material and details of all business relations and transactions, including
                    documents for recording transactions in the accounting books;
                </li>
                <li>Relevant documents of correspondence with the customers and other persons with whom they keep a
                    business relation.
                </li>
            </ul>
            <p>All documents are available rapidly to the authorities for the purpose of discharging the duties
                imposed on them by the law. Documents and information must be original or certified true copies.</p>

            <h2>Training of Employees</h2>
            <p>The company conducts thorough background checks on employees during recruitment and provides ongoing
                training. Training is customized for different employee categories, including new hires, frontline
                staff, compliance personnel, and those dealing with new customers.</p>
            <p>New employees are taught the importance of Know Your Customer (KYC) policies and basic
                requirements.</p>
            <p>Customer-facing employees are trained to verify customer identities, practice ongoing due diligence
                with existing accounts, and identify suspicious activity patterns.</p>
            <p>Regular refresher training is also provided to reinforce employee responsibilities and keep them
                updated on industry developments.</p>

            <h2>Reviews</h2>
            <p>Reputable audit firms conduct regular audits to ensure compliance with policies and procedures. These
                policies are regularly reviewed to align with current regulations and external factors.</p>
            <p>If you have any questions, please don't hesitate to contact us using the provided contact
                details.</p>

            <h2>About Us</h2>
            <p>FXA-Trading is a global brokerage committed to providing a versatile trading environment accessible
                to all levels of traders, from beginners to professionals. Our platform offers a wide range of
                highly liquid assets, enabling traders to trade and invest according to their preferences and
                goals.</p>
            <p>“Our clients come first”, We offer a secure and advanced trading platform, prioritizing exceptional
                customer service with expert support available via phone, email, and live chat to address all
                inquiries.</p>

            <h2>Contacts</h2>
            <p>Unlock the path to financial independence. Take action today and seize the market's opportunities
                with our comprehensive range of services and solutions.</p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: "TermsAndCondition",
    computed: {
        ...mapGetters(["langActive"])
    }
}
</script>

<style scoped>
.title {
    padding: 15px 0;
    font-weight: 400;
    font-size: 2.1em;
    color: var(--white-to-black);
}

.content {
    overflow: scroll;
    height: 500px;
}

h2, h3 {
    color: var(--white-to-black);
    margin-bottom: 15px;
}

P {
    margin-bottom: 15px;
}

ul li {
    margin: 0 10px 10px;
}

ul {
    margin-bottom: 5px;
}
</style>
