<template>
    <div class="modal-wrapper"
         @click="closeCalc"
         v-show="visible && isMobile"
    >
        <div class="modal" @click.stop=""
             :style="{
            top: position.top+'px',
            left: position.left+'px'
        }"
        >
            <div class="calc">
                <div class="calc-text">{{ calcValue }}</div>
                <div class="buttons-row">
                    <div class="calc-button" @click.stop="updateCalcText(7)">7</div>
                    <div class="calc-button" @click.stop="updateCalcText(8)">8</div>
                    <div class="calc-button" @click.stop="updateCalcText(9)">9</div>
                </div>
                <div class="buttons-row">
                    <div class="calc-button" @click.stop="updateCalcText(4)">4</div>
                    <div class="calc-button" @click.stop="updateCalcText(5)">5</div>
                    <div class="calc-button" @click.stop="updateCalcText(6)">6</div>
                </div>
                <div class="buttons-row">
                    <div class="calc-button" @click.stop="updateCalcText(1)">1</div>
                    <div class="calc-button" @click.stop="updateCalcText(2)">2</div>
                    <div class="calc-button" @click.stop="updateCalcText(3)">3</div>
                </div>
                <div class="buttons-row">
                    <div class="calc-button" @click.stop="updateCalcText('.')">.</div>
                    <div class="calc-button" @click.stop="updateCalcText(0)">0</div>
                    <div class="calc-button" @click.stop="backCalcText"><i class="material-icons" style="font-size: 1.2em">backspace</i> </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isMobile} from '@/common/helpers'

    export default {
        name: "Calculator",
        props: ['input', 'visible'],
        data() {
            return {
                isMobile: isMobile(),
                calcValue: null
            }
        },
        methods: {
            updateCalcText(t) {
                if ( t === 0 && this.calcValue === '0') {
                    return
                } else if ( [0, '.'].indexOf(t) === -1 && this.calcValue === '0' ) {
                    this.calcValue = String(t)
                } else if ( t === '.' && this.calcValue.indexOf('.') > -1 ) {
                    return
                } else if ( t === '.' && this.calcValue.length === 0 ) {
                    return
                } else {
                    this.calcValue += t
                }

                this.sendNumberOutside()
            },
            backCalcText() {
                this.calcValue = this.calcValue.length === 1 ? '0' : this.calcValue.slice(0, this.calcValue.length-1)

                this.sendNumberOutside()
            },

            sendNumberOutside() {
                this.$emit('number-changed', this.calcValue)
            },
            closeCalc() {
                this.$emit('close')

                this.calcValue = null
            }
        },
        computed: {
            position() {
                if (!this.calcValue) {
                    return {
                        left: 0,
                        top: 0
                    }
                }

                let screen = {
                        width: document.body.offsetWidth,
                        height: document.body.offsetHeight,
                    },
                    modal = {
                        width: 320,
                        height: 256
                    }

                return {
                    left: 0,
                    top: screen.height - modal.height - 140 - 30

                }
            }
        },
        watch: {
            visible(val, old) {
                if (this.calcValue === null && val && !old) {
                    this.calcValue = String(this.input)
                }
            }
        }
    }
</script>

<style scoped>

.modal-wrapper {
    position: absolute;
    z-index: 200;
}

.modal {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background: var(--background-dark);
    z-index: 200;
    top: 127px;
    right: 110px;
    width: 180px;
}

.amounts {
    flex: 0 0 140px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
}

.amount-col.pad {
    margin-left: -140px;
}

.amount-col {
    flex: 0 0 140px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    transition: margin-left ease-in-out 0.3s;
}

.amt {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid var(--border);
    font-size: 1.1em;
    color: var(--white);
    transition: background-color ease-in-out 0.3s;
    cursor: pointer;
}

.amt:hover {
    background-color: var(--background-dark-mid);
}

.balance {
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.balance span {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 4px;
}

.w-val {
    font-size: 1.1em;
    color: var(--white)
}

.last-amounts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
    cursor: pointer;
}

.last-amounts.left {
    justify-content: flex-start;
}

.last-amounts span {
    font-size: 0.9em;
    font-weight: 400;
    transition: color ease-in-out 0.3s;
}

.last-amounts i {
    transition: color ease-in-out 0.3s;
}

.last-amounts:hover span, .last-amounts:hover i {
    color: var(--white)
}

.toggle-calc button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 100%;
}

.toggle-calc button i {
    font-size: 1.5em;
}

.calc {
    flex: 0 0 180px;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.calc .calc-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: var(--white);
    background-color: var(--background-light);
    transition: background-color ease-in-out 0.3s;
    cursor: pointer;
    height: 100%;
    flex: 1 1 33.3%;
    margin-right: 2px;
    font-weight: 400;
}

.calc .calc-button:last-child {
    margin-right: 0;
}

.calc .calc-button:hover {
    background-color: var(--background);
}

.calc .buttons-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 2px;
    height: 42px;
}

.calc-text {
    display: flex;
    font-size: 3em;
    color: var(--white);
    align-items: center;
    justify-content: flex-end;
    max-width: 150px;
    overflow: hidden;
    flex: 0 0 50px
}

.calc-button i {
    color: var(--font);
}

.coef {
    font-size: 1.1em;
    font-weight: 400;
}

.calc .buttons-row-small {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 30px;
    margin: 4px 0;
}

.calc .buttons-row-small input {
    font-size: 1.2em;
    color: var(--white);
    background-color: var(--background-light);
    cursor: pointer;
    height: 100%;
    margin-right: 2px;
    font-weight: 400;
    text-align: center;
    width: 85px;
    border: 1px solid var(--border);
    transition: background-color ease-in-out 0.3s;
}

.calc .buttons-row-small input:focus {
    background-color: transparent;
}

.calc .buttons-row-small .calc-button {
    flex: 1 1 25%
}

.grey, .grey-ast {
    font-size: 2em !important;
    color: var(--font) !important;
    transition: color ease-in-out 0.3s;
}

.grey-ast {
    font-size: 2.4em !important;
    padding-top: 8px;
}

.calc .buttons-row-small .grey:hover , .calc .buttons-row-small .grey-ast:hover {
    color: var(--white) !important;
}

</style>