<template>
    <table>
        <thead>
            <tr>
                <th>
                    {{tokens.ordersPane.symbol}}
                </th>
                <th class="no-mobile">
                    {{tokens.ordersPane.id}}
                </th>
                <th>
                    {{tokens.ordersPane.type}}
                </th>
                <th>
                    {{tokens.ordersPane.volume}}
                </th>
                <th class="no-mobile">
                    {{tokens.ordersPane.openPrice}}
                </th>
                <th class="no-mobile">
                    {{tokens.ordersPane.openTime}}
                </th>
                <th>
                    {{tokens.ordersPane.closePrice}}
                </th>
                <th class="no-mobile pointer" @click="sort('time_close')">
                    {{tokens.ordersPane.closeTime}}
                </th>
                <th class="no-mobile">
                    {{tokens.ordersPane.commission}}
                </th>
                <th class="no-mobile">
                    {{tokens.ordersPane.swap}}
                </th>
                <th>
                    {{tokens.ordersPane.pnl}}
                </th>
            </tr>
        </thead>
        <tbody ref="tables-container">
            <tr v-for="order in history"
                :key="order.id"
            >
                <td>
<!--                    <img :src="staticBase+order.symbol.toUpperCase()+'.png'" />-->
                    <span class="symbol" :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : ''" />
                    {{symbols[order.symbol] ? symbols[order.symbol].alias : ''}}
                </td>
                <td class="no-mobile">
                    {{order.id}}
                </td>
                <td :class="order.type % 2 ? 'red' : 'green'">
                    {{orderTypeString(order.type)}}
                </td>
                <td>
                    {{order.volume.toLocaleString()}}
                </td>
                <td class="no-mobile">
                    {{symbols[order.symbol] ? order.price_open.toLocaleString(undefined, { minimumFractionDigits: symbols[order.symbol].precision, maximumFractionDigits: symbols[order.symbol].precision }) : 0}}
                </td>
                <td class="no-mobile">
                    {{new Date(order.time_open * 1000).toLocaleString()}}
                </td>
                <td>
                    {{symbols[order.symbol] && order.price_close ? order.price_close.toLocaleString(undefined, { minimumFractionDigits: symbols[order.symbol].precision, maximumFractionDigits: symbols[order.symbol].precision }) : '0'}}
                </td>
                <td class="no-mobile">
                    {{new Date(order.time_close * 1000).toLocaleString()}}
                </td>
                <td class="no-mobile">
                    {{ currencySign(activeAccount.currency) + order.commission.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
                </td>
                <td class="no-mobile">
                    {{ currencySign(activeAccount.currency) + order.swap.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
                </td>
                <td :class="order.profit > 0 ? 'green' : (order.profit < 0 ? 'red' : '')">
                    {{currencySign(activeAccount.currency)+order.profit.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision })}}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {orderTypeString, timeToString, currencySign} from "@/common/helpers"
    import {STATIC_BASE} from "@/common/config"
    import {GET_ORDERS_HISTORY} from "@/store/actions.type";

    export default {
        name: "HistoryTable",
        props: ['active'],
        mounted() {
            setInterval(() => {
                if (!this.active) {
                    return
                }

                let container = document.getElementById('tables-container')

                if (container.scrollTop + container.clientHeight === container.scrollHeight && this.activeAccount.history.length > this.historyPage * 20) {
                    this.historyPage++
                }
            }, 1000)
        },
        data() {
            return {
                historyPage: 1,
                staticBase: STATIC_BASE
            }
        },
        methods: {
            orderTypeString,
            timeToString,
            currencySign,
            sort(data) {
                this.$store.dispatch(GET_ORDERS_HISTORY, {
                    sortBy: data,
                    account: this.activeAccount.id
                })
            }
        },
        computed: {
            ...mapGetters(['symbols', 'tokens', 'activeAccount']),
            history() {
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

                let history = []

                if (!this.activeAccount.history) {
                    return history
                }

                for(let n in this.activeAccount.history.slice(0, this.historyPage * 20)) {
                    let order = this.activeAccount.history[n],
                        d = new Date(order.time_close * 1000)

                    order.timeStr = ('0'+d.getHours()).slice(-2)+':'+('0'+d.getMinutes()).slice(-2)
                    order.dayStr = monthNames[d.getMonth()]+' '+('0'+d.getDate()).slice(-2)
                    order.month = monthNames[d.getMonth()]
                    order.day = ('0'+d.getDate()).slice(-2)

                    order.amount = order.easy_amount || order.margin

                    history.push(order)
                }

                this.$emit('send-ordersHistory', { history })

                return history
            }
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
        position: relative;
    }

    tr:nth-child(even) td {
        background-color: rgba(255,255,255,0.02);
    }

    td, th {
        white-space: nowrap;
        text-align: right;
    }

    th {
        padding: 8px 0;
        position: sticky;
        top: 0;
        background-color: var(--background-dark);
    }

    th.pointer {
        cursor: pointer;
    }

    td {
        padding: 3px 0;
    }

    td:first-child, th:first-child {
        text-align: left;
        padding-left: 10px;
    }

    td:last-child, th:last-child {
        padding-right: 10px;
    }

    td:first-child img {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin-right: 5px;
    }

    td .symbol {
        zoom: 0.21875;
        margin-right: 23px;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 15px;
    }

    @-moz-document url-prefix() {
        td .symbol {
            -moz-transform: scale(0.21875);
            -moz-transform-origin: 50% 50%;
            position: absolute;
            left: -16px;
            top: -22px;
        }

        td:first-child {
            position: relative;
            padding-left: 28px !important;
        }
    }
</style>
