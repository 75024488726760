<template>
    <div class="modal-wrapper" v-show="modals.depositSuccess" @click.stop="hideModal">
        <div class="modal" @click.stop>
            <div class="title">
                <span>{{ tokens.depositSuccessModal.title }}</span>
                <i class="material-icons">check</i>
            </div>
            <div class="row">
                {{ tokens.depositSuccessModal.text }}
            </div>
            <div class="row">
                <button @click.stop="hideModal">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL} from "../../store/mutations.type";

export default {
    name: "NewPasswordModal",
    computed: {
        ...mapGetters(['modals', 'tokens'])
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                depositSuccess: false
            })
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white-to-black);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

button {
    width: 100%;
    padding: 8px;
    margin-top: 15px;
    font-weight: 600;
    background: var(--green);
    cursor: pointer;
}

</style>