<template>
    <div :class="{hide: !modals.assetsInfo}" class="cabinet-modal">
        <div v-if="modals.assetsInfo">
            <div class="background">
                <img
                    :src="staticBase+`backgrounds/${symbols[modalAssetsInfo.symbol].symbol.toUpperCase()}.jpg`"
                    alt="">
            </div>
            <div class="title">
                <div class="close" v-on:click="hideModal">
                    <i class="material-icons">close</i>
                </div>
                <div class="name">{{ tokens.assetInfoModal.assetInfo }}</div>
            </div>
            <div class="container">
                <div class="divider nomobile">
                </div>
                <div class="menu">
                    <div class="box">
                        <div class="info-brand">
                        <span
                            :class="'symbol-'+symbols[modalAssetsInfo.symbol].symbol.toUpperCase()"
                            class="symbol"/>
                            <div>{{ symbols[modalAssetsInfo.symbol].alias }}</div>
                        </div>

                        <div class="state">
                            <div class="open-trade">{{ sessionState }}</div>
                        </div>
                    </div>
                    <div class="back">
                        <div :class="{active: page === 'trading'}" class="item" @click="page = 'trading'">{{ tokens.assetInfoModal.tradingConditions }}</div>
                        <div>
                            <div :class="{active: page === 'information'}" class="item" @click="page = 'information'">{{ tokens.assetInfoModal.information }}</div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div v-if="sessionStateBool">
                        <div class="trade">
                            <div class="left">
                                <div class="box-trade">
                                    <div class="title-trade">{{ tokens.assetInfoModal.bid }}</div>
                                    <div class="values">{{ symbols[modalAssetsInfo.symbol].quote.bid.toFixed(symbols[modalAssetsInfo.symbol].precision) }}
                                    </div>
                                </div>
                                <div class="box-trade">
                                    <div class="title-trade">{{ tokens.assetInfoModal.ask }}</div>
                                    <div class="values">{{ symbols[modalAssetsInfo.symbol].quote.ask.toFixed(symbols[modalAssetsInfo.symbol].precision) }}
                                    </div>
                                </div>
                                <div class="box-trade">
                                    <div class="title-trade">{{ tokens.assetInfoModal.sessionChange }}</div>
                                    <div class="cur"
                                        :class="symbols[modalAssetsInfo.symbol].quote ? (symbols[modalAssetsInfo.symbol].quote.changePct < 0 ? 'red' : ( 0 < symbols[modalAssetsInfo.symbol].quote.changePct ? 'green' : '')) : ''"
                                    >
                                        {{ (symbols[modalAssetsInfo.symbol].quote && symbols[modalAssetsInfo.symbol].quote.changePct > 0 ? '+' : '') + (symbols[modalAssetsInfo.symbol].quote && symbols[modalAssetsInfo.symbol].quote.changePct ? symbols[modalAssetsInfo.symbol].quote.changePct : 0).toFixed(2) + '%' }}
                                    </div>
                                </div>
                            </div>
                            <div class="btns-wrapper">
                                <div class="btns">
                                    <i :class="{active: symbolsFavorite.indexOf(modalAssetsInfo.symbol) !== -1}"
                                       class="material-icons fav"
                                       v-on:click.stop="toggleFavorite(modalAssetsInfo.symbol)"
                                    >star</i>
                                    <button @click.stop="changeSymbol(modalAssetsInfo.symbol)">{{ tokens.assetInfoModal.tradeNow.toUpperCase() }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Information :chart="charts" :class="{hide: page !== 'information'}"
                                     :symbolActive="modalAssetsInfo.symbol"
                                     class="cabinet-item"/>
                    </div>
                    <TradingConditions :class="{hide: page !== 'trading'}"
                                       :symbolActive="modalAssetsInfo.symbol" class="cabinet-item"/>
                </div>
                <div class="divider nomobile">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, SYMBOL_FAVORITE, SYMBOL_ACTIVE_UPDATE} from '@/store/mutations.type'
import {STATIC_BASE} from "@/common/config"
import Information from '@/components/AssetsInfo/Information'
import TradingConditions from '@/components/AssetsInfo/TradingConditions'
import {dateCorr} from '@/common/helpers'

export default {
    name: 'AssetsInfoModal',
    components: {Information, TradingConditions},
    mounted() {
        let now = Date.now()
        setTimeout(() => {
            setInterval(() => {
                this.checkSessionState()
            }, 60000)
        } , Math.ceil(now / 60000) * 60000 - now)
    },
    data() {
        return {
            staticBase: STATIC_BASE,
            newData: [],
            sessionState: '',
            sessionStateBool: false,
            page: 'trading'
        }
    },
    methods: {
        changeSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.modalAssetsInfo.chartActive
            })
            this.$store.commit(MODAL, {
                assetsInfo: false,
            })
        },
        hideModal() {
            this.$store.commit(MODAL, {
                assetsInfo: false
            })
        },
        toggleFavorite(symbol) {
            this.$store.commit(SYMBOL_FAVORITE, {
                action: this.symbolsFavorite.indexOf(symbol) === -1 ? 'add' : 'remove',
                symbol
            })
        },
        checkSessionState() {
            if (!this.symbols[this.modalAssetsInfo.symbol]) {
                return
            }

            if (JSON.stringify(this.symbols[this.modalAssetsInfo.symbol].sessions) === '[[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]]') {
                this.sessionState = this.tokens.assetInfoModal.open247
                this.sessionStateBool = true
                return
            }

            let date = dateCorr(),
                d = date.getUTCDay(),
                h = date.getUTCHours(),
                m = date.getUTCMinutes(),
                s = this.symbols[this.modalAssetsInfo.symbol].sessions[d][h],
                prev = h > 0 ? this.symbols[this.modalAssetsInfo.symbol].sessions[d][h - 1] : (d > 0 ? this.symbols[this.modalAssetsInfo.symbol].sessions[d - 1][23] : this.symbols[this.modalAssetsInfo.symbol].sessions[6][23]),
                next = h > 0 ? this.symbols[this.modalAssetsInfo.symbol].sessions[d][h + 1] : (d > 0 ? this.symbols[this.modalAssetsInfo.symbol].sessions[d + 1][23] : this.symbols[this.modalAssetsInfo.symbol].sessions[6][23])

            const glueSession = session => {
                let r = []

                for (let i = 0; i < 7; i++) {
                    r = r.concat(session[i])
                }

                return r
            }

            const getInitState = initState => {
                if (initState > 0 && initState < 1 && (initState * 60) > m) {
                    return this.tokens.assetInfoModal.opens
                } else if (initState > 0 && initState < 1 && (initState * 60) < m) {
                    return this.tokens.assetInfoModal.closes
                } else {
                    return initState ? this.tokens.assetInfoModal.closes : this.tokens.assetInfoModal.opens
                }
            }

            const dateToStr = date => {
                let now = dateCorr(),
                    ret = '',
                    dateDay = date.getDay(),
                    nowDay = now.getDay()

                if (dateDay === nowDay) {
                    ret = this.tokens.assetInfoModal.today
                } else if (dateDay - nowDay === 1 || dateDay - nowDay === -6) {
                    ret = this.tokens.assetInfoModal.tomorrow
                } else {
                    switch (dateDay) {
                        case 0:
                            ret = this.tokens.assetInfoModal.sunday
                            break
                        case 1:
                            ret = this.tokens.assetInfoModal.monday
                            break
                        case 2:
                            ret = this.tokens.assetInfoModal.tuesday
                            break
                        case 3:
                            ret = this.tokens.assetInfoModal.wednesday
                            break
                        case 4:
                            ret = this.tokens.assetInfoModal.thursday
                            break
                        case 5:
                            ret = this.tokens.assetInfoModal.friday
                            break
                        case 6:
                            ret = this.tokens.assetInfoModal.saturday
                            break
                    }
                }

                return ret + ' ' + this.tokens.assetInfoModal.at + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
            }

            if ((s !== 1 && s !== 0 && m < s * 60) || next !== 0 && next !== 1) {
                date.setUTCHours(s === 0 ? ++h : h)
                date.setUTCMinutes(s === 0 ? next * 60 : s * 60)

                this.sessionState = ((s === 0 ? s * 60 : next * 60 < m ? !prev : !!prev) ? this.tokens.assetInfoModal.closes : this.tokens.assetInfoModal.opens) + ' ' + dateToStr(date)
                this.sessionStateBool = !!(s === 0 ? s * 60 : next * 60 < m ? !prev : !!prev)
            } else {
                let i = d * 24 + h + 1,
                    hours = 1,
                    gluedSession = glueSession(this.symbols[this.modalAssetsInfo.symbol].sessions),
                    initState = gluedSession[i - 1],
                    start = gluedSession[i],
                    minutesIsCalculate = false,
                    stop = start ? 0 : 1,
                    iteration = 0

                while (i < 24 * 7 && (start !== stop || start > stop)) {
                    i++
                    iteration++
                    start = gluedSession[i]
                    if ([0, 1].indexOf(gluedSession[i]) === -1 && !minutesIsCalculate) {
                        hours += gluedSession[i]
                        minutesIsCalculate = true
                    } else {
                        hours += 1
                    }

                    if (i >= 24 * 7) {
                        i = 0
                    }

                    if (iteration >= ((7 * 24) * 2)) {
                        console.error('Infinity Loop')
                        break
                    }
                }

                date.setUTCHours(h + Math.floor(hours))
                date.setUTCMinutes(Math.round((hours - Math.floor(hours)) * 60))

                this.sessionState = getInitState(initState) + ' ' + dateToStr(date)
                this.sessionStateBool = !!initState
            }
        }
    },
    computed: {
        ...mapGetters(['modals', 'modalAssetsInfo', 'symbols', 'symbolsFavorite', 'charts', 'modals', 'tokens']),
    },
    watch: {
        modals: {
            deep: true,
            handler(val) {
                if (!val.modalAssetsInfo) {
                    this.page = 'trading'
                }
            }
        },
        modalAssetsInfo: {
            deep: true,
            handler() {
                this.checkSessionState()
            }
        }
    }
}
</script>

<style scoped>

.cabinet-modal {
    position: absolute;
    z-index: 5;
    top: 81px;
    bottom: 0;
    left: 101px;
    right: 0;
    background-color: var(--background-dark);
    display: flex;
    flex-direction: column;
}

.container {
    display: grid;
    grid-template-columns: 10% 20% auto 10%;
    overflow: hidden;
    gap: 10px;
}

.content {
    overflow-y: auto;
    padding-bottom: 20px;
    position: relative;
}

.inner {
    margin-top: 70px;
}

.mobile .container {
    grid-template-columns: none;
}

.title-trade i {
    cursor: pointer;
}

.trade {
    background-color: var(--background-dark);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 10px;
    padding: 15px;
    align-items: center;
}

.trade .left {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.box-trade .title {
    font-weight: 400;
    font-size: 1.3em;
}

.box-trade .values {
    font-size: 1.5em;
    font-weight: 500;
    color: var(--white-to-black);
    margin-top: 10px;
}

.box-trade .red, .green, .cur {
    margin-top: 10px;
    font-size: 1.3em;
}

.box-trade .red {
    color: var(--red);
}

.box-trade .green {
    color: var(--green);
}

.box-trade div {
    font-weight: 400;
}

.box-trade .tooltip {
    font-size: 11px;
}

.btns {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: end;
    gap: 10px;
    align-items: center;
}

.btns i {
    font-size: 2.4em;
    cursor: pointer;
    transition: color .3s ease-in-out;
}

.light-theme .btns button {
    background-color: var(--font);
}

.btns i.active {
    color: var(--brand);
}

.btns i:hover {
    color: var(--brand);
}

.background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
}

.background::after {
    content: '';
    position: absolute;
    background: var(--background-dark);
    background: var(--assets-info-gradient);
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.background img {
    height: auto;
    width: 100%;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 30px 0;
}

.title .close {
    background-color: var(--background-dark);
    border-radius: 50%;
    position: absolute;
    left: 20px;
    top: -5px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease-in-out 0.3s;
}

.title .close i {
    font-size: 2.5em;
    transition: color ease-in-out 0.3s;
    color: var(--font);
}

.title .close:hover {
    background-color: var(--background-light);
}

.title .close:hover i {
    color: var(--font)
}

.title .name {
    font-size: 2em;
    color: var(--font);
    font-weight: 400;
}
.light-theme .title .name {
    color: var(--white);
}

.info-brand {
    display: grid;
    grid-template-columns: 64px auto;
    place-items: center flex-start;
    margin-bottom: 20px;
    gap: 10px;
}

.info-brand div {
    font-size: 1.6em;
    color: var(--white-to-black);
    font-weight: 400;
}

.state {
    display: grid;
    grid-template-rows:repeat(2, auto);
    gap: 10px;
}

.time {
    font-size: 1.3em;
}

.open-trade,
.close-trade {
    font-size: 1.5em;
    color: var(--white-to-black);
    font-weight: 400;
}

.menu .box {
    margin-bottom: 15px;
}

.back{
    background-color: var(--background-dark);
}

.menu .back {
    background-color: var(--background-dark-mid);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.menu .back.opacity {
    margin-bottom: 20px;
    background-color: transparent;
}

.menu .back .item {
    padding: 15px 20px;
    border-bottom: 1px solid var(--border);
    color: var(--white-to-black);
    border-radius: 3px;
    font-weight: 400;
    cursor: pointer;
}

.menu .back .item:last-child {
    border-bottom: none;
}

.menu .back .item:hover, .menu .back .item.active {
    background-color: var(--border);
}

@media (max-width: 1100px) {
    .container {
        grid-template-columns: auto;
    }

    .cabinet-modal {
        left: 0;
        top: 0;
        height: 100vh;
        width: 100%;
    }

    .background {
        left: 0;
        top: 0;
    }

    .title .name {
        margin-left: 30px;
    }

    .container {
        flex-direction: column;
        overflow-y: scroll;
    }

    .menu {
        margin: 0 10px 15px;
        flex: initial;
        overflow-x: scroll;
    }

    .menu .back {
        flex-direction: row;
        overflow-x: scroll;
    }

    .menu .back .item {
        padding: 4px 10px;
        white-space: nowrap;
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content {
        overflow-y: auto;
    }

    .trade {
        grid-template-columns: none;
        grid-template-rows: repeat(2, 1fr);
        margin: 0px 10px;
        place-items: center center;
    }

    .trade .left {
        margin-bottom: 20px;
    }

    .left .box-trade {
        margin-right: 20px;
    }
}

@media (max-width: 500px) {

    .btns button {
        padding: 7px 9px;
    }

    .box-trade .red {
        margin-bottom: 10px;
    }

    .background::after {
        height: 90px;
    }
}
</style>
