<template>
    <div class="modal-wrapper" :class="{hide: !modals.register}">
        <div class="modal">
            <div class="title">
                <span>{{ tokens.registerModal.createNewAccount.toUpperCase() }}</span>
                <i class="material-icons">lock</i>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-row">
                        <label>{{ tokens.registerModal.email }}</label>
                        <input class="form-control"
                               @focus="registerForm.email.valid = true"
                               v-model="registerForm.email.value"
                               @keyup.enter="register" :class="{ 'invalid-input': !registerForm.email.valid }"
                               tabindex="1"/>
                    </div>
                    <div class="form-row">
                        <label>{{ tokens.registerModal.firstName }}</label>
                        <input
                            @focus="registerForm.firstName.valid = true"
                            class="form-control"
                            @input="handleNameInput('firstName')"
                            v-model="registerForm.firstName.value"
                            @keyup.enter="register"
                            tabindex="3"
                            :maxlength="maxCharacterLimit"
                            :class="{ 'invalid-input': !registerForm.firstName.valid }"
                        />
                    </div>
                    <div class="form-row">
                        <label>{{ tokens.registerModal.country }}</label>
                        <div class="search-item">
                            <button class="search-country"
                                    :class="{ 'invalid-input': !registerForm.country.valid }"
                                    @click="countrySearchList = !countrySearchList">
                                {{registerForm.country.value}} {{countryPhoneConfig ? `(${countryPhoneConfig.code})` : ''}}
                            </button>
                            <div class="country-list" v-if="countrySearchList">
                                <input placeholder="Search..." class="search-list" v-model="searchedCountry"/>
                                <div class="country-items">
                                    <p  v-for="(countryItem, index) in countries" :key="index" @click="chooseCountry(countryItem)">
                                        {{ countryItem.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <label>{{ tokens.loginModal.promo }}</label>
                        <div class="show-hide-password">
                            <input class="form-control" ref="password" type="password" v-model="manager_id"
                                   @keyup.enter="register"
                                   tabindex="7"/>
                            <i class="material-icons"
                               @touchstart="showPass($refs.password)"
                               @touchend="hidePass($refs.password)"
                               @touchmove="hidePass($refs.password)"
                               @mousedown="showPass($refs.password)"
                               @mouseup="hidePass($refs.password)"
                               @dragover="hidePass($refs.password)"
                               @mouseout="hidePass($refs.password)">visibility</i>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-row">
                        <label>{{ tokens.registerModal.phone }}</label>
                        <input class="form-control"
                               :disabled="!registerForm.country.value"
                               @input="handlePhoneInput"
                               @focus="registerForm.phone.valid = true"
                               v-mask="maskedPhone"
                               :class="{ 'invalid-input': !registerForm.phone.valid }"
                               v-model="registerForm.phone.value" tabindex="2"
                        />
                    </div>
                    <div class="form-row">
                        <label>{{ tokens.registerModal.lastName }}</label>
                        <input
                            class="form-control"
                            v-model="registerForm.lastName.value"
                            @input="handleNameInput('lastName')"
                            @focus="registerForm.lastName.valid = true"
                            @keyup.enter="register"
                            tabindex="4"
                            :maxlength="maxCharacterLimit"
                            :class="{ 'invalid-input': !registerForm.lastName.valid }"
                        />
                    </div>
                    <div class="form-row">
                        <label>{{ tokens.registerModal.password }}</label>
                        <div class="show-hide-password">
                            <input class="form-control"
                                   ref="password2"
                                   type="password"
                                   @focus="registerForm.password.valid = true"
                                   v-model="registerForm.password.value"
                                   :class="{ 'invalid-input': !registerForm.password.valid }"
                                   @keyup.enter="register"
                                   tabindex="6"/>
                            <i class="material-icons"
                               @touchstart="showPass($refs.password2)"
                               @touchend="hidePass($refs.password2)"
                               @touchmove="hidePass($refs.password2)"
                               @mousedown="showPass($refs.password2)"
                               @mouseup="hidePass($refs.password2)"
                               @dragover="hidePass($refs.password2)"
                               @mouseout="hidePass($refs.password2)">visibility</i>
                        </div>
                    </div>
                    <div class="form-row">
                        <label>{{ tokens.registerModal.currency }}</label>
                        <select v-model="currencyChosen" class="form-control"
                                @keyup.enter="register" tabindex="7">
                            <option v-for="(currency, i) in currencies" :key="i" :value="currency">{{
                                    currency
                                }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="button green-button" v-on:click="register">
                    {{ tokens.registerModal.createAccount.toUpperCase() }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {REGISTER} from "@/store/actions.type"
import {NOTIFICATION_ERROR} from '@/store/mutations.type'
import {ROLE, STATIC_BASE, BRAND} from "@/common/config"
import {hidePass, showPass, fullCountriesList} from "@/common/helpers"

export default {
    name: "RegisterModal",
    data() {
        return {
            staticBase: STATIC_BASE,
            manager_id: '',
            existPromo: false,
            role: ROLE,
            currencyChosen: 'USD',
            countrySearchList: false,
            searchedCountry: '',
            maxCharacterLimit: 50,
            errors: null,
            maskedPhone: null,
            countryPhoneConfig: null,
            brand: BRAND,
            registerForm: {
                phone: {
                  value: '',
                  valid: true
                },
                country: {
                    value: '',
                    valid: true
                },
                password: {
                    value: '',
                    valid: true
                },
                email: {
                    value: '',
                    valid: true
                },
                firstName: {
                    value: '',
                    valid: true
                },
                lastName: {
                    value: '',
                    valid: true
                }
            }
        }
    },
    methods: {
        showPass, hidePass,
        validateData(field) {
            let regex = null,
                value = null

            if(!this.countryPhoneConfig) return

            const {min, max, code} = this.countryPhoneConfig
            const phoneLength = this.registerForm.phone.value.length - code.length

            switch (field) {
                case 'email':
                    value = this.registerForm.email.value
                    regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    this.registerForm.email.valid = value && regex.test(value)
                    return
                case 'firstName':
                    value = (this.registerForm.firstName.value = this.registerForm.firstName.value.trim())
                    regex = ['stage_sharksoft','optixcapital'].includes(this.brand) ? /^[a-zA-Z\s]+$/ : /^[a-zA-Z]+$/
                    this.registerForm.firstName.valid = value && regex.test(value) && value && value.length <= this.maxCharacterLimit
                    return
                case 'lastName':
                    value = (this.registerForm.lastName.value = this.registerForm.lastName.value.trim())
                    regex = ['stage_sharksoft','optixcapital'].includes(this.brand) ? /^[a-zA-Z\s]+$/ : /^[a-zA-Z]+$/
                    this.registerForm.lastName.valid = value && regex.test(value) && value.length <= this.maxCharacterLimit
                    return
                case 'password':
                    value = this.registerForm.password.value
                    this.registerForm.password.valid = value
                    return
                case 'phone':
                    value = this.registerForm.phone.value
                    this.registerForm.phone.valid = value && value.startsWith(this.countryPhoneConfig.code) && (value.length === this.maskedPhone.length || phoneLength === min || phoneLength === max)
                    return
                case 'country':
                    value = this.registerForm.country.value
                    this.registerForm.country.valid = value
                    return
                default:
                    return
            }
        },
        handlePhoneInput() {
            this.registerForm.phone.value = this.registerForm.phone.value.replace(/[^+\d-]/g, "")
        },
        handleNameInput(field) {
            let regex =  ['stage_sharksoft','optixcapital'].includes(this.brand) ? /[^a-zA-Z\s]/g : /[^a-zA-Z]/g
            this.registerForm[field].value = this.registerForm[field].value.replace(regex, "")
        },
        chooseCountry(country) {
            this.registerForm.country.value = country.name
            this.registerForm.country.valid = true
            this.registerForm.phone.valid = true
            this.countrySearchList = false
            this.setPhone(country)
        },
        setPhone(country) {
            this.countryPhoneConfig = country
            if (this.countryPhoneConfig) {
                let phone = this.countryPhoneConfig.code
                let phoneFormat = this.countryPhoneConfig.mask || 'XXX-XXXXXX'

                this.$set(this.registerForm.phone, 'value', phone)
                this.$set(this, 'maskedPhone', phoneFormat)
            }
        },
        validateAllFields() {
            Object.keys(this.registerForm).forEach((fieldName) => {
                this.validateData(fieldName)
            })
        },
        register() {
            this.validateAllFields()
            let hasErrors = Object.values(this.registerForm)
                .some((field) => !field.valid || !field.value)

            if (hasErrors) {
                this.$store.commit(NOTIFICATION_ERROR, 'INVALID_FIELDS')
                return
            }

            let manager_id = parseInt(this.manager_id)
            if (isNaN(manager_id) || manager_id <= 0) {
                manager_id = 0
            }

            this.$store.dispatch(REGISTER, {
                firstname: this.registerForm.firstName.value,
                lastname: this.registerForm.lastName.value,
                country: this.registerForm.country.value,
                phone: this.registerForm.phone.value.replaceAll('-', ''),
                email: this.registerForm.email.value.trim(),
                currency: this.currencyChosen,
                password: this.registerForm.password.value.trim(),
                manager_id,
                role: this.role
            })
        }
    },
    computed: {
        ...mapGetters(['modals', 'tokens', 'currencies']),
        countries() {
            return Object.values(fullCountriesList()).filter(country => country.name.toLowerCase().indexOf(this.searchedCountry.toLowerCase()) > -1)
        }
    }
}
</script>

<style scoped>
.search-country {
    width: 100%;
    height: 31px;
    text-align: left;
    border-radius: 0;
}

.light-theme .search-country{
    color: var(--white-to-black);
    background: var(--background-active);
}

.search-item {
    position: relative;
    width: 100%;
}

.country-list {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    background-color: var(--background-dark);
    padding: 8px 0;
    z-index: 10;
    display: grid;
    grid-template-rows: 1fr max-content;
    gap: 10px;
}

.country-list p {
    color: var(--white-to-black);
    cursor: pointer;
    transition: all .3s ease-in-out;
    padding: 0 12px;
    background-color: transparent;
    font-size: 14px;
}

.country-list input {
    padding: 5px;
    outline: none;
    border: 0;
    background-color: var(--background-light);
    margin-bottom: 5px;
    border-radius: 5px;
    color: var(--white-to-black);
}

.light-theme .country-list input {
    background-color: var(--background-active);
}

.country-list p.active,
.country-list p:hover {
    background-color: var(--brand);
}

.country-items {
    max-height: 200px;
    overflow: scroll;
    padding-top: 5px;
}

.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.phone-flag {
    top: 50%;
    transform: translateY(-50%);
}

.footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    align-items: center;
}

.form-login p {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.form-row.textCenter {
    align-items: center;
}

.form-login p a {
    text-decoration: underline;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--white-to-black);
    margin-left: 5px;
    cursor: pointer;
}

.modal {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white-to-black);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input, select {
    width: 100%;
}

.row {
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

.invalid-input {
    border: 1px solid var(--red);
}
</style>
